import { LoadingOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-components";
import { Button, Empty, Popconfirm, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MdDeleteOutline, MdEdit } from "react-icons/md";

import { UploadFile } from "antd/lib/upload/interface";
import MainContext from "../../../../../../contexts/MainContext";
import TeamsContext from "../../../../../../contexts/TeamsContext";
import TypesContext from "../../../../../../contexts/ToolInputsContext";
import equalObjects from "../../../../../../helpers/equalObjects";
import generateUUID from "../../../../../../helpers/generateUUID";
import * as TypesService from "../../services/predefinedData.service";
import EditableTable from "../EditableTable/EditableTable";
import AddDraftingTemplateModal from "./components/AddDraftingTemplateModal";
import { useToast } from "../../../../../../contexts/ToastContext";

import styles from "./DocumentDraftingInputs.module.css";

interface DataSourceType {
  key: React.Key;
  id: React.Key;
  name: string;
  description: string;
  file_name: string;
  processed_data: string;
}

const DocumentDraftingInputs: React.FC<{}> = (_) => {
  const mainContext = useContext(MainContext);
  const teamsContext = useContext(TeamsContext);
  const typesContext = useContext(TypesContext);

  const { showSuccessToast, toastResponse } = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [editableTemplateKeys, setEditableTemplateKeys] = useState<React.Key[]>(
    []
  );
  const [isCreateTemplateModalVisible, setIsCreateTemplateModalVisible] =
    useState(false);

  const showCreateTemplateModal = () => {
    setIsCreateTemplateModalVisible(true);
  };

  const onCreateTemplate = async (
    name: string,
    description: string,
    file: UploadFile,
    baseline: string = "weak"
  ) => {
    const responseData = await TypesService.createDraftingTemplateType(
      {
        name,
        description,
        file,
        baseline,
      },
      teamsContext?.teamSelected?.id!
    );

    toastResponse<{ name: string }>(responseData).then((result) => {
      showSuccessToast(
        "Success",
        <p>
          Document drafting template{" "}
          <span className={styles.bold_italic_text}>{result.name}</span> was
          created successfully!
        </p>
      );
    });

    await fetchToolsInput();

    mainContext?.fetchNotifications();
  };

  const draftingTemplateColumns: ProColumns<DataSourceType>[] = [
    {
      title: "Name",
      dataIndex: "name",
      valueType: "text",
      formItemProps: () => ({
        rules: [
          {
            required: true,
            max: 100,
            message: "Invalid input: between 0 - 100 characters.",
          },
        ],
      }),
      fieldProps: () => ({ placeholder: "Template name" }),
      width: "15vw",
    },
    {
      title: "Description",
      dataIndex: "description",
      valueType: "text",
      formItemProps: () => ({
        rules: [
          { max: 20000, message: "Invalid input: max 20000 characters." },
        ],
      }),
      fieldProps: () => ({ placeholder: "Template description" }),
      width: "40vw",
    },
    {
      title: "Actions",
      valueType: "option",
      width: "10vw",
      render: (text, record, _, action) => [
        <Tooltip key={`${record.id}_edit`} title="Edit" placement="left">
          <Button
            type="link"
            icon={<MdEdit color="var(--deloitte-green)" size="22" />}
            onClick={(e) => {
              e.preventDefault();
              action?.startEditable?.(record.id);
            }}
          />
        </Tooltip>,
        <Tooltip key={`${record.id}_delete`} title="Delete" placement="right">
          <Popconfirm
            title="Are you sure you want to delete this template?"
            okText="Yes"
            cancelText="No"
            onConfirm={async () => {
              setIsTableLoading(true);
              await TypesService?.deleteDraftingTemplateType(
                record.id.toString()
              );
              typesContext?.fetchDraftingTemplateTypes(
                teamsContext?.teamSelected?.id!
              );
              setIsTableLoading(false);
            }}>
            <Button
              type="link"
              icon={<MdDeleteOutline color="var(--beautiful-red)" size="22" />}
            />
          </Popconfirm>
        </Tooltip>,
      ],
      hidden: teamsContext?.teamSelected?.user_role !== "Admin",
    },
  ];

  const fetchToolsInput = async () => {
    await typesContext?.fetchDraftingTemplateTypes(
      teamsContext?.teamSelected?.id!
    );
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchToolsInput();
  }, [teamsContext?.teamSelected]);

  return isLoading ? (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
      }}>
      <LoadingOutlined
        style={{ fontSize: 20, color: "var(--deloitte-green)" }}
      />
    </div>
  ) : (
    <div className={styles.content}>
      <div className={styles.template_type_item_content}>
        <div className={styles.template_type_item_title}>Templates</div>
        {
          <EditableTable<DataSourceType>
            rowKey="id"
            showHeader
            tableStyle={{ top: 30 }}
            tableLayout="fixed"
            loading={isTableLoading}
            recordCreatorProps={
              teamsContext?.teamSelected?.user_role === "Admin"
                ? {
                    position: "bottom",
                    record: () => ({
                      id: generateUUID(),
                    }),
                    creatorButtonText: "Add new template",
                    newRecordType: "dataSource",
                    onClick: showCreateTemplateModal,
                    disabled: isTableLoading,
                  }
                : false
            }
            columns={draftingTemplateColumns}
            value={typesContext?.draftingTemplateTypes.map(
              (templateType, index) => ({
                key: index,
                id: templateType.id,
                name: templateType.name,
                description: templateType.description,
                file_name: templateType.file_name,
                processed_data: templateType.processed_data,
              })
            )}
            editable={{
              type: "multiple",
              editableKeys: editableTemplateKeys,
              onSave: async (key, data, row) => {
                const isNew = row.key === undefined;
                if (equalObjects(data, row) || isNew) return;
                setIsTableLoading(true);
                await TypesService?.editDraftingTemplateType(
                  data.id.toString(),
                  {
                    name: data.name,
                    description: data.description,
                  }
                );
                typesContext?.fetchDraftingTemplateTypes(
                  teamsContext?.teamSelected?.id!
                );
                setIsTableLoading(false);
              },
              onChange: setEditableTemplateKeys,
              onlyAddOneLineAlertMessage:
                "Please add only one item per template at a time.",
              cancelText: <div style={{ color: "#c7c7c7" }}>Cancel</div>,
              saveText: (
                <div style={{ color: "var(--deloitte-green)" }}>Save</div>
              ),
              actionRender: (_, __, dom) => [dom.save, dom.cancel],
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No template available"
                />
              ),
            }}
          />
        }
        <AddDraftingTemplateModal
          isModalVisible={isCreateTemplateModalVisible}
          setIsModalVisible={setIsCreateTemplateModalVisible}
          onSubmit={onCreateTemplate}
        />
      </div>
    </div>
  );
};

export default DocumentDraftingInputs;

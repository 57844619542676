import React, { ReactNode, useContext, useRef, useState } from "react";
import { Button } from "antd";
import {
  CheckOutlined,
  CopyOutlined,
  SaveOutlined,
  EditOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from 'remark-gfm'

import {
  MDXEditor,
  headingsPlugin,
  listsPlugin,
  thematicBreakPlugin,
  quotePlugin,
  tablePlugin,
  linkPlugin,
  toolbarPlugin,
  linkDialogPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  InsertTable,
  CreateLink,
  ListsToggle,
  BlockTypeSelect,
  Separator,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";
import handleCopyToClipboard from "../../helpers/handleCopyToClipboard";
import ResultsContext from "../../../../contexts/ResultsContext";
import SpinWrapper from "../../../../components/SpinWrapper/SpinWrapper";

import styles from "./EditableMarkdown.module.css";

interface EditableMarkdownProps {
  title?: string | ReactNode;
  text: string;
  isEdited: boolean;
  enableCopy?: boolean;
  editingRequest: (editedText: string) => Promise<void>;
}

const EditableMarkdown: React.FC<EditableMarkdownProps> = ({
  title = "",
  text,
  isEdited,
  enableCopy = true,
  editingRequest,
}) => {
  const resultsContext = useContext(ResultsContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [updatedText, setUpdatedText] = useState("");
  const resultTextRef = useRef<HTMLDivElement>(null);

  return (
    <SpinWrapper isLoading={isLoading}>
      <div className={styles.expanded_result_header}>
        <div className={styles.expanded_result_title}>{title}</div>
        <div className={styles.result_buttons}>
          {isEditing ? (
            <div className={styles.changings_buttons}>
              <Button
                type="primary"
                size="small"
                shape="round"
                icon={<SaveOutlined />}
                onClick={async () => {
                  setIsLoading(true);

                  await editingRequest(updatedText);
                  resultsContext?.setFetchingToggle((prev) => !prev);

                  setIsEditing(false);
                  setIsLoading(false);
                }}>
                Save changes
              </Button>
              <Button
                type="primary"
                size="small"
                danger
                shape="round"
                icon={<CloseOutlined />}
                onClick={() => setIsEditing(false)}>
                Cancel
              </Button>
            </div>
          ) : (
            <div className={styles.editing_wrapper}>
              {isEdited && (
                <p className={styles.is_edited_info}>The result was edited.</p>
              )}
              <Button
                type="dashed"
                icon={<EditOutlined />}
                onClick={() => setIsEditing(true)}>
                Edit result
              </Button>
            </div>
          )}

          {enableCopy &&
            (isCopied ? (
              <p>
                <CheckOutlined /> Copied!
              </p>
            ) : (
              <Button
                disabled={isEditing}
                type="dashed"
                icon={<CopyOutlined />}
                onClick={async () => {
                  await handleCopyToClipboard(resultTextRef);
                  setIsCopied(true);
                  setTimeout(() => {
                    setIsCopied(false);
                  }, 3000);
                }}>
                Copy text
              </Button>
            ))}
        </div>
      </div>

      <div ref={resultTextRef}>
        {isEditing ? (
          <MDXEditor
            markdown={text}
            onChange={setUpdatedText}
            contentEditableClassName={styles.editable_content}
            plugins={[
              headingsPlugin(),
              listsPlugin(),
              thematicBreakPlugin(),
              quotePlugin(),
              tablePlugin(),
              linkPlugin(),
              linkDialogPlugin(),
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    {" "}
                    <UndoRedo />
                    <Separator />
                    <BoldItalicUnderlineToggles />
                    <Separator />
                    <BlockTypeSelect />
                    <Separator />
                    <ListsToggle />
                    <Separator />
                    <InsertTable />
                    <CreateLink />
                  </>
                ),
              }),
            ]}
          />
        ) : (
          <Markdown
            rehypePlugins={[rehypeRaw,]}
            remarkPlugins={[remarkGfm]}
            className={styles.expanded_result_content}>
            {text}
          </Markdown>
        )}
      </div>
    </SpinWrapper>
  );
};

export default EditableMarkdown;

const outputFormatOptions = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Bullet points",
    value: "bullet_points",
  },
];

export default outputFormatOptions;

import { Cascader, CascaderProps } from "antd";
import React from "react";

const extractText = (element: any): string => {
  if (typeof element === "string") {
    return element;
  }
  if (Array.isArray(element)) {
    return element.map(extractText).join(" ");
  }
  if (element.props && element.props.children) {
    return extractText(element.props.children);
  }
  return "";
};

const displayRender = (labels: React.ReactNode[]) => {
  const mappedLabels = labels
    .map((label) => {
      const text = extractText(label);
      const firstLine = text.split("\n")[0];
      return firstLine;
    })
    .filter((label) => label !== "");

  if (mappedLabels.length === 1) return mappedLabels;
  return mappedLabels.join(" / ");
};
/**
 * Cascader which utilizes options displaying with multiple lines.
 * displayRender changes multiline selection, so it's displayed as one line after cascader closes
 */
const MultilineCascader: React.FC<CascaderProps<any>> = (props) => {
  return <Cascader {...props} displayRender={displayRender} />;
};

export default MultilineCascader;

import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import type { TableColumnsType } from "antd";
import { Tooltip, Table, Tabs } from "antd";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { FaCheckCircle, FaInfoCircle, FaTimesCircle } from "react-icons/fa";
import parseDateString from "../../../../helpers/parseDateString";
import formatDate from "../../../../helpers/formatDate";
import {
  ToolResult,
  ComparisonResult,
} from "../../interfaces/toolsResults.interface";
import { TableData } from "../../interfaces/tableData.interface";
import ResultActions from "../ResultActions/ResultActions";
import { ResultTypes } from "../../enums/resultTypes.enum";
import { ToolResultProps } from "../../interfaces/toolResultProps";
import EditableMarkdown from "../EditableMarkdown/EditableMarkdown";
import getCookie from "../../../../helpers/getCookie";
import * as ResultsService from "../../services/results.service";

import styles from "./ComparisonResults.module.css";

const ComparisonResults: React.FC<ToolResultProps> = ({
  isLoading,
  allResults,
  getColumnSearchProps,
  selectRows,
  expandedRowKeys,
  setExpandedRowKeys,
  currentPage,
  setCurrentPage,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const csrfToken = getCookie("csrftoken") ?? "";

  const handleExpand = (expanded: boolean, record: TableData) => {
    let newExpandedRowKeys = [...expandedRowKeys];
    if (expanded) {
      newExpandedRowKeys.push(record.key as number);
    } else {
      newExpandedRowKeys = newExpandedRowKeys.filter(
        (key) => key !== record.key
      );
    }
    setExpandedRowKeys(newExpandedRowKeys);

    // Update URL with the expanded rows
    const params = new URLSearchParams(window.location.search);
    if (newExpandedRowKeys.length > 0) {
      params.set("expandedRowKeys", newExpandedRowKeys.join(","));
    } else {
      params.delete("expandedRowKeys");
    }
    navigate({ search: params.toString() }, { replace: true });
  };

  const columns: TableColumnsType<TableData> = [
    {
      title: "File",
      dataIndex: "file",
      key: "file",
      ...getColumnSearchProps("file"),
      sorter: (a, b) => a.file.localeCompare(b.file),
      sortDirections: ["ascend", "descend"],
      width: "66%",
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      ...getColumnSearchProps("user"),
      sortDirections: ["ascend", "descend"],
      width: "140px",
      render: (value) => (
        <div title={value} className={styles.user}>
          {value}
        </div>
      ),
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) =>
        parseDateString(a.createdAt).getTime() -
        parseDateString(b.createdAt).getTime(),
      sortDirections: ["ascend", "descend"],
      width: "140px",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "70px",
      align: "center",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "220px",
      render: (_, record, ___) => (
        <ResultActions record={record} resultType={ResultTypes.Comparison} />
      ),
      align: "center",
    },
  ];

  const checkStatus = (result: ToolResult) => {
    if (result.results.length === 0)
      return (
        <Tooltip title="Process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      );
    const comparisonResult = result.results[0];
    if (
      (comparisonResult.comparison_type === "regulation_predefined" ||
        comparisonResult.comparison_type === "regulation_custom" ||
        comparisonResult.comparison_type === "checklist_predefined" ||
        comparisonResult.comparison_type === "checklist_custom") &&
      (!comparisonResult.risk_level ||
        !comparisonResult.potential_risks ||
        !comparisonResult.risks_mitigation ||
        !comparisonResult.compliance_report ||
        !comparisonResult.compliance_recommendations)
    ) {
      return (
        <Tooltip title="Not all the data was extracted.">
          <FaInfoCircle color="yellow" />
        </Tooltip>
      );
    }
    if (
      comparisonResult.comparison_type !== "regulation_predefined" &&
      comparisonResult.comparison_type !== "regulation_custom" &&
      comparisonResult.comparison_type !== "checklist_predefined" &&
      comparisonResult.comparison_type !== "checklist_custom" &&
      (!comparisonResult.risk_level ||
        !comparisonResult.potential_risks ||
        !comparisonResult.risks_mitigation ||
        !comparisonResult.compliance_report ||
        !comparisonResult.compliance_recommendations ||
        !comparisonResult.redrafted_clauses)
    ) {
      return (
        <Tooltip title="Not all the data was extracted.">
          <FaInfoCircle color="yellow" />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="All data was extracted.">
        <FaCheckCircle color="var(--deloitte-green)" />
      </Tooltip>
    );
  };

  const createComparisonResultTabs = (result: ComparisonResult) => (
    <>
      <p>
        Risk Level:{" "}
        <span className={styles[`${result.risk_level.toLowerCase()}_risk`]}>
          {result.error ? (
            <Tooltip title="Internal server error occurred while identifying the risk level.">
              <FaTimesCircle color="var(--beautiful-red)" />
            </Tooltip>
          ) : result.risk_level ? (
            result.risk_level
          ) : (
            <Tooltip title="Risk level not identified.">
              <FaInfoCircle color="yellow" />
            </Tooltip>
          )}
        </span>
      </p>
      <Tabs
        type="card"
        items={[
          {
            label: "Compliance report",
            key: "1",
            children: result.error ? (
              <Tooltip title="Internal server error occurred while generating a report.">
                <FaTimesCircle color="var(--beautiful-red)" />
              </Tooltip>
            ) : result.compliance_report ? (
              <EditableMarkdown
                text={result.compliance_report}
                isEdited={result.is_edited}
                editingRequest={async (editedText: string) => {
                  await ResultsService.editComparisonComplianceReportResult(
                    csrfToken,
                    result.id,
                    editedText
                  );
                }}
              />
            ) : (
              <Tooltip title="Could not generate a report.">
                <FaInfoCircle color="yellow" />
              </Tooltip>
            ),
          },
          {
            label: "Recommendations",
            key: "2",
            children: result.error ? (
              <Tooltip title="Internal server error occurred while generating recommendations.">
                <FaTimesCircle color="var(--beautiful-red)" />
              </Tooltip>
            ) : result.compliance_recommendations ? (
              <EditableMarkdown
                text={result.compliance_recommendations}
                isEdited={result.is_edited}
                editingRequest={async (editedText: string) => {
                  await ResultsService.editComparisonRecommendationsResult(
                    csrfToken,
                    result.id,
                    editedText
                  );
                }}
              />
            ) : (
              <Tooltip title="Could not generate recommendations.">
                <FaInfoCircle color="yellow" />
              </Tooltip>
            ),
          },
        ]}
      />
    </>
  );

  const expandedRowRender = (record: TableData) => {
    const expandedRowResults = allResults.filter(
      (result) => result.id === record.key
    )[0].results;

    return expandedRowResults!.length === 0 ? (
      <div className={styles.error_result}>
        <Tooltip title="This process failed.">
          <FaTimesCircle color="var(--beautiful-red)" />
        </Tooltip>
      </div>
    ) : (
      createComparisonResultTabs(expandedRowResults![0])
    );
  };

  return (
    <div className={styles.table}>
      <Table
        bordered
        loading={isLoading}
        sticky={{ offsetHeader: 0 }}
        rowSelection={{
          type: "checkbox",
          onChange: (val) => {
            selectRows(val);
          },
        }}
        size="middle"
        pagination={{
          defaultPageSize: 15,
          current: currentPage, // Add current page control
          onChange: (page) => setCurrentPage(page), // Update current page
        }}
        scroll={{ scrollToFirstRowOnChange: true }}
        columns={columns}
        dataSource={allResults.map((result) => ({
          key: result.id,
          file: result.file,
          user: result.user,
          status: checkStatus(result),
          createdAt: formatDate(result.created_at),
        }))}
        expandable={{
          expandedRowRender: expandedRowRender,
          onExpand: handleExpand,
          expandedRowKeys: expandedRowKeys,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <UpCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ) : (
              <DownCircleOutlined
                style={{ fontSize: 16 }}
                onClick={(e) => onExpand(record, e)}
              />
            ),
        }}
      />
    </div>
  );
};

export default ComparisonResults;

import React, { useState } from "react";
import { Tooltip, Upload, UploadFile, Button } from "antd";
import { AiOutlineFileAdd, AiFillFolderAdd } from "react-icons/ai";
import UploadFilesModal from "../UploadFilesModal/UploadFilesModal";
import styles from "./FilesUploader.module.css";

interface UploadChangeParam<T = UploadFile> {
  file: T;
  fileList: T[];
  event?: { percent: number };
}

const FilesUploader = () => {
  const [filesToUpload, setFilesToUpload] = useState<UploadFile[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const acceptedFileTypes =
    ".mp4, .mov, .mp3, .docx, .doc, .dot, .edoc, .xlsx, .xls, .csv, .xml, .json, .txt, .pdf, .pptx, .png, .jpg, .jpeg, .tiff, .tif, .eml, .xhtml, .html, .htm, .vsdx, .avi, .wmv, .flv, .webm, .mkv";

  const handleFileChange = ({ fileList }: UploadChangeParam) => {
    const updatedFileList = fileList.map((file) => {
      const relativePath = file.originFileObj?.webkitRelativePath || file.name;
      const formattedName = relativePath; // Replace slashes or backslashes with hyphens
   
      return {
        ...file,
        name: formattedName, // Update the name to the formatted path
      };
    });
  
    setFilesToUpload(updatedFileList);
    setIsModalOpen(true);
  };

  return (
    <>
      <div className={styles.button_container}>
        <Tooltip
          overlayClassName={styles.uploader_tooltip}
          title={
            <div className={styles.title_container}>
              <p className={styles.hint}>
                You can also drop files here directly.
              </p>
              <p>Accepted files</p>
              <p className={styles.accepted_types}>{acceptedFileTypes}</p>
            </div>
          }
          placement="bottom"
        >
          <Upload.Dragger
            multiple
            fileList={[]}
            className={styles.file_upload_dragger}
            accept={acceptedFileTypes}
            beforeUpload={() => false}
            onChange={handleFileChange}
          >
            <p className={styles.file_upload_dragger_text}>
              <AiOutlineFileAdd />
              Files
            </p>
          </Upload.Dragger>
        </Tooltip>

        <Tooltip
          overlayClassName={styles.uploader_tooltip}
          title={
            <div className={styles.title_container}>
              <p className={styles.hint}>
                All matching files from folder and subfolders will be added.
              </p>
              <p>Accepted files</p>
              <p className={styles.accepted_types}>{acceptedFileTypes}</p>
            </div>
          }
          placement="bottom"
        >
          <Upload.Dragger
            multiple
            directory
            fileList={[]}
            className={styles.file_upload_dragger}
            accept={acceptedFileTypes}
            beforeUpload={() => false}
            onChange={handleFileChange}
          >
            <p className={styles.file_upload_dragger_text}>
              <AiFillFolderAdd />
              Folder
            </p>
          </Upload.Dragger>
        </Tooltip>
      </div>

      <UploadFilesModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        files={filesToUpload}
        setFiles={setFilesToUpload}
      />
    </>
  );
};

export default FilesUploader;

import { IconType } from "react-icons";
import { ToolTypes } from "../../../../../enums/toolTypes.enum";
import {
  MdOutlineDataObject,
  MdOutlineDocumentScanner,
  MdTranslate,
} from "react-icons/md";
import { PiHandshake } from "react-icons/pi";
import { CgDanger, CgFeed, CgFormatLineHeight } from "react-icons/cg";
import { TbLayersDifference } from "react-icons/tb";
import { LuPencil } from "react-icons/lu";
import { FiTool } from "react-icons/fi";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";

type toolsButtonDataMap = {
  [key in ToolTypes]: {
    icon: IconType;
    title: string;
    inDevMode?: boolean;
  };
};

const toolsButtonData: toolsButtonDataMap = {
  [ToolTypes.MetadataExtractor]: {
    icon: MdOutlineDataObject,
    title: "Metadata extractor",
  },
  [ToolTypes.ObligationsExtractor]: {
    icon: PiHandshake,
    title: "Obligations extractor",
  },
  [ToolTypes.RisksIdentifier]: {
    icon: CgDanger,
    title: "Risks identifier",
  },
  [ToolTypes.DocumentComparison]: {
    icon: TbLayersDifference,
    title: "Document comparison",
  },
  [ToolTypes.DocumentDrafting]: {
    icon: LuPencil,
    title: "Document drafting",
  },
  [ToolTypes.DocumentSummarization]: {
    icon: CgFormatLineHeight,
    title: "Document summarization",
  },
  [ToolTypes.TimelineAnalysis]: {
    icon: MdOutlineDocumentScanner,
    title: "Timeline analysis",
  },
  [ToolTypes.DocumentStandardization]: {
    icon: CgFeed,
    title: "Document standardization",
    inDevMode: true,
  },
  [ToolTypes.Translator]: {
    icon: MdTranslate,
    title: "Translation",
  },
  [ToolTypes.TransactionReview]: {
    icon: LiaMoneyCheckAltSolid,
    title: "Transactions review",
  },
  [ToolTypes.CustomTool]: {
    icon: FiTool,
    title: "Custom tool",
    inDevMode: true,
  },
};

export default toolsButtonData;

import React, { useContext, useState } from "react";
import { Button, Checkbox, Form, Select, Tooltip } from "antd";
import { ClearOutlined } from "@ant-design/icons";
import TransitionToolWrapper from "../TransitionToolWrapper/TransitionToolWrapper";
import ToolBase from "../ToolBase/ToolBase";
import { LiaMoneyCheckAltSolid } from "react-icons/lia";
import { ImInsertTemplate } from "react-icons/im";
import { TransactionForm } from "./interfaces/transactionForm.interface";
import filterOption from "../../../../helpers/selectFilterOption";
import {
  dateFormatingOptions,
  valueFormatingOptions,
} from "./helpers/formatingOptions";
import languagesOptions from "../../../../helpers/languagesOptions";
import ToolsContext from "../../../../contexts/ToolsContext";
import ConfirmToolModal from "../ConfirmToolModal/ConfirmToolModal";
import { ToolTypes } from "../../../../enums/toolTypes.enum";
import MainContext from "../../../../contexts/MainContext";
import CreateTemplateModal from "../CreateTemplateModal/CreateTemplateModal";
import getCookie from "../../../../helpers/getCookie";
import * as ToolsService from "../../services/tools.service";
import { useToast } from "../../../../contexts/ToastContext";
import truncateFilesArrayPipe from "../../../../helpers/truncateFilesArrayPipe";

import styles from "./TransactionReviewTool.module.css";

const TransactionReviewTool: React.FC = () => {
  const mainContext = useContext(MainContext);
  const toolsContext = useContext(ToolsContext);
  const { showSuccessToast, toastResponse } = useToast();

  const csrfToken = getCookie("csrftoken") ?? "";

  const [template, setTemplate] = useState<
    { value: number; label: string } | undefined
  >(undefined);

  const [templates, setTemplates] = useState<
    Array<{ value: number; label: string }>
  >([]);

  const [fetchingTemplates, setFetchingTemplates] = useState(false);

  const [formData, setFormData] = useState<TransactionForm>({
    team: undefined,
    transactionType: undefined,
    dateFormating: { value: "dd.mm.yyyy", label: "DD.MM.YYYY" },
    valueFormating: {
      value: "decimal with 2 digits",
      label: "Decimal [2 digits]",
    },
    language: "English",
  });

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const getTemplate = async (template: { value: number; label: string }) => {
    setTemplate(template);
    if (!template) {
      setFormData({
        team: undefined,
        transactionType: undefined,
        dateFormating: { value: "dd.mm.yyyy", label: "DD.MM.YYYY" },
        valueFormating: {
          value: "decimal with 2 digits",
          label: "Decimal [2 digits]",
        },
        language: "English",
      });
    }
  };

  const createTemplate = async (templateName: string) => {
    console.log("CREATE TEMPLATE");
  };

  const updateTemplate = async (templateName: string) => {
    console.log("UPDATE TEMPLATE");
  };

  const deleteTemplate = async (data: { value: number; label: string }) => {
    console.log("DELETE TEMPLATE");
    setTemplates((templates) =>
      templates.filter((template) => template.value !== data.value)
    );
    setTemplate(undefined);
  };

  return (
    <>
      <TransitionToolWrapper>
        <ToolBase
          icon={<LiaMoneyCheckAltSolid />}
          title="Transactions review"
          template={template}
          getTemplate={getTemplate}
          fetchingTemplates={fetchingTemplates}
          templates={templates}
          deleteTemplate={deleteTemplate}
          isDevelopmentMode={true}
        >
          <Form className={styles.form} layout="vertical">
            <Form.Item label="Team" required>
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option
                    ? option.label.toLowerCase().includes(input.toLowerCase())
                    : false
                }
                placeholder="Select team"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    team: option
                      ? { id: option.value, name: option.label }
                      : undefined,
                  })
                }
                value={formData.team?.name}
                options={[
                  { id: 1, name: "Polish BPS" },
                  { id: 2, name: "Czech BPS" },
                  { id: 3, name: "Polish Transfer Pricing" },
                ].map((team) => ({
                  value: team.id,
                  label: team.name,
                }))}
              />
            </Form.Item>

            <Form.Item label="Transactions type">
              <Select
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option
                    ? option.label.toLowerCase().includes(input.toLowerCase())
                    : false
                }
                placeholder="Select transaction type"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    transactionType: option
                      ? { id: option.value, name: option.label }
                      : undefined,
                  })
                }
                value={formData.transactionType?.name}
                options={[
                  { id: 1, name: "Accounts Payable Transactions" },
                  { id: 2, name: "Accounts Receivable Transactions" },
                  { id: 3, name: "E-billing Legal Transactions" },
                  { id: 4, name: "Expenses" },
                  { id: 5, name: "Purchase Orders" },
                  { id: 6, name: "Intercompany" },
                  { id: 7, name: "Freight and Shipping" },
                ].map((transactionType) => ({
                  value: transactionType.id,
                  label: transactionType.name,
                }))}
              />
            </Form.Item>

            <div className={styles.formatings_container}>
              <Form.Item label="Date formating" style={{ width: "45%" }}>
                <Select
                  onChange={(value: string, option: any) =>
                    setFormData({
                      ...formData,
                      dateFormating: {
                        value: option.value,
                        label: option.label,
                      },
                    })
                  }
                  value={formData.dateFormating.value}
                  options={dateFormatingOptions}
                />
              </Form.Item>

              <Form.Item label="Value formating" style={{ width: "45%" }}>
                <Select
                  onChange={(value: string, option: any) =>
                    setFormData({
                      ...formData,
                      valueFormating: {
                        value: option.value,
                        label: option.label,
                      },
                    })
                  }
                  value={formData.valueFormating.value}
                  options={valueFormatingOptions}
                />
              </Form.Item>
            </div>

            <Form.Item
              className={styles.language_input}
              label="Language of the output"
            >
              <Select
                showSearch
                filterOption={(input, option) =>
                  option
                    ? option.label.toLowerCase().includes(input.toLowerCase())
                    : false
                }
                placeholder="Select the language of the output"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    language: option.label,
                  })
                }
                value={formData.language}
                options={languagesOptions}
              />
            </Form.Item>

            <div className={styles.tool_footer}>
              <Button
                type="primary"
                ghost
                size="middle"
                icon={<ClearOutlined className={styles.button_icon} />}
                disabled={!formData.team}
                onClick={() => {
                  setFormData({
                    team: undefined,
                    transactionType: undefined,
                    dateFormating: { value: "dd.mm.yyyy", label: "DD.MM.YYYY" },
                    valueFormating: {
                      value: "decimal with 2 digits",
                      label: "Decimal [2 digits]",
                    },
                    language: "English",
                  });
                }}
              >
                Clear All Fields
              </Button>

              <Button
                type="primary"
                size="middle"
                icon={<LiaMoneyCheckAltSolid className={styles.button_icon} />}
                disabled={
                  toolsContext?.selectedFiles.length === 0 ||
                  !formData.team ||
                  !formData.dateFormating ||
                  !formData.valueFormating ||
                  !formData.language
                }
                onClick={() => setShowSubmitModal(true)}
                loading={showSubmitModal}
              >
                Review Transactions
              </Button>

              <Tooltip title="In Development">
                <Button
                  type="primary"
                  ghost
                  size="middle"
                  icon={<ImInsertTemplate className={styles.button_icon} />}
                  disabled
                  onClick={() => setShowCreateTemplateModal(true)}
                >
                  {template ? "Update Template" : "Create Template"}
                </Button>
              </Tooltip>
            </div>
          </Form>
        </ToolBase>
      </TransitionToolWrapper>

      <ConfirmToolModal
        isOpen={showSubmitModal}
        title="Review Transactions"
        subTitle="Proceed by reviewing transactions from these files:"
        icon={<LiaMoneyCheckAltSolid className={styles.modal_icon} />}
        leftPart={{
          header: "Files",
          body: toolsContext?.selectedFiles.map((file) => file.name)!,
        }}
        rightPart={{
          header: "Options",
          body: [
            formData.team?.name ?? "No team selected",
            formData.transactionType?.name ?? "Auto detected transaction",
            formData.dateFormating.label,
            formData.valueFormating.label,
          ],
        }}
        onClose={() => setShowSubmitModal(false)}
        action={async () => {
          toolsContext?.setSelectedTools((prev) =>
            prev.filter((tool) => tool !== ToolTypes.TransactionReview)
          );

          const responseData = await ToolsService.extractTransactionData(
            csrfToken,
            toolsContext?.selectedFiles.map((file) => file.id)!,
            formData.team?.name || ""
          );

          toastResponse(responseData).then(() => {
            showSuccessToast(
              "Success",
              <p>
                Review of transactions from{" "}
                <span className={styles.bold_italic_text}>
                  {truncateFilesArrayPipe(
                    toolsContext?.selectedFiles.map(({ name }) => name)
                  )}{" "}
                </span>
                was completed successfully!
              </p>
            );
          });

          await mainContext?.fetchNotifications();
        }}
      />

      <CreateTemplateModal
        isOpen={showCreateTemplateModal}
        title={
          template
            ? "Update Transaction Review Template"
            : "New Transaction Review Template"
        }
        subTitle={
          template
            ? "Type a new name for this template"
            : "Please type a name for new transaction review template."
        }
        onClose={() => setShowCreateTemplateModal(false)}
        action={template ? updateTemplate : createTemplate}
        predefinedValue={template ? template.label : ""}
      />
    </>
  );
};

export default TransactionReviewTool;

import React from "react";
import { ResultTypes } from "../enums/resultTypes.enum";
import { ToolResultProps } from "../interfaces/toolResultProps";
import MetadataResults from "../components/MetadataResults/MetadataResults";
import ObligationsResults from "../components/ObligationsResults/ObligationsResults";
import RisksResults from "../components/RisksResults/RisksResults";
import ComparisonResults from "../components/ComparisonResults/ComparisonResults";
import DraftingResults from "../components/DraftingResults/DraftingResults";
import TranslationResults from "../components/TranslationResults/TranslationResults";
import SummarizationResults from "../components/SummarizationResults/SummarizationResults";
import TransactionResults from "../components/TransactionResults/TransactionResults";
import TimelineResults from "../components/TimelineResults/TimelineResults";

type ResultComponentsMap = {
  [key in ResultTypes]: React.ComponentType<ToolResultProps>;
};

const ResultComponents: ResultComponentsMap = {
  [ResultTypes.Metadata]: MetadataResults,
  [ResultTypes.Obligations]: ObligationsResults,
  [ResultTypes.Risks]: RisksResults,
  [ResultTypes.Comparison]: ComparisonResults,
  [ResultTypes.Drafting]: DraftingResults,
  [ResultTypes.Translation]: TranslationResults,
  [ResultTypes.Summarization]: SummarizationResults,
  [ResultTypes.Transaction]: TransactionResults,
  [ResultTypes.Timeline]: TimelineResults,
};

export default ResultComponents;

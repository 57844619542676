import React from "react";
import { Button, Table, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import type { DragEndEvent } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import DragHandler from "./components/DragHandler/DragHandler";
import DragableRow from "./components/DragableRow/DragableRow";
import { MetadataForm } from "../../interfaces/metadataForm.interface";

import styles from "./CustomMetadataTable.module.css";

interface CustomMetadataTableProps {
  data: {
    id: string | number;
    metadata_parameter: string;
    description: string;
    metadata_type: string;
    output_format: string;
  }[];
  isEditing: boolean;
  editRowItem: (value: string) => void;
  deleteRowItem: (value: string) => void;
  setData: React.Dispatch<React.SetStateAction<MetadataForm>>;
}

const CustomMetadataTable: React.FC<CustomMetadataTableProps> = ({
  data,
  isEditing,
  editRowItem,
  deleteRowItem,
  setData,
}) => {
  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setData((prevState): MetadataForm => {
        const activeIndex = prevState.customMetadata.findIndex(
          (record) => record.id === active?.id
        );
        const overIndex = prevState.customMetadata.findIndex(
          (record) => record.id === over?.id
        );
        return {
          ...prevState,
          customMetadata: arrayMove(
            prevState.customMetadata,
            activeIndex,
            overIndex
          ),
        };
      });
    }
  };
  const totalRecords = data.length;
  const pageSizeOptions = totalRecords > 18 ? ["6", "12", "18", totalRecords.toString()] : ["6", "12", "18"];
  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={data.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          pagination={{ defaultPageSize: 6 , pageSizeOptions, showSizeChanger: true }}
          
          locale={{
            emptyText: (
              <div className={styles.empty_state}>
                Custom metadata has not been added.
              </div>
            ),
          }}
          size="small"
          components={{ body: { row: DragableRow } }}
          columns={[
            {
              key: "sort",
              align: "center",
              width: 80,
              render: () => <DragHandler />,
            },
            {
              title: "Custom metadata",
              dataIndex: "customMetadata",
              key: "customMetadata",
              width: "30%",
              ellipsis: { showTitle: false },
              render: (val: string) => (
                <Tooltip placement="topLeft" title={val}>
                  {val}
                </Tooltip>
              ),
            },
            {
              title: "Custom metadata description",
              dataIndex: "customMetadataDescription",
              key: "customMetadataDescription",
              width: "38%",
              ellipsis: { showTitle: false },
              render: (val: string) => (
                <Tooltip placement="topLeft" title={val}>
                  {val}
                </Tooltip>
              ),
            },
            {
              title: "Output Format",
              dataIndex: "outputFormat",
              key: "outputFormat",
              width: "22%",
              ellipsis: { showTitle: false },
              render: (val: string) => (
                <Tooltip placement="topLeft" title={val}>
                  {val}
                </Tooltip>
              ),
            },
            {
              title: "Actions",
              dataIndex: "actions",
              key: "actions",
              align: "center",
              width: "10%",
              ellipsis: { showTitle: false },
              render: (val: string) => (
                <div className={styles.action_buttons}>
                  <Button
                    disabled={isEditing}
                    type="text"
                    onClick={() => editRowItem(val)}
                    icon={<EditOutlined />}
                  />

                  <Button
                    disabled={isEditing}
                    type="text"
                    onClick={() => deleteRowItem(val)}
                    icon={<DeleteOutlined />}
                  />
                </div>
              ),
            },
          ]}
          dataSource={data
            .map((customOption: any) => ({
              key: customOption.id,
              customMetadata: customOption.metadata_parameter,
              customMetadataDescription: customOption.description,
              outputFormat: `${customOption.metadata_type} ${
                customOption.output_format
                  ? "(" + customOption.output_format + ")"
                  : ""
              }`,
              actions: `${customOption.id}`,
            }))
            .reverse()}
        />
      </SortableContext>
    </DndContext>
  );
};

export default CustomMetadataTable;

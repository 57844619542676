import getCookie from "../../../helpers/getCookie";

async function exportDocxTemplated(md: string, id?: string | null, template_id?: number | null): Promise<void> {
    const backendUrl = process.env.REACT_APP_API_URL ?? "";

    const endpoint = `${backendUrl}/api/export_docx_templated`;

    try {
        // Prepare the request payload
        const payload = {
            markdown: md,
            original_file_name: id,
            template_id: template_id
        };

        const csrfToken = getCookie("csrftoken");

        // Make the POST request
        const response = await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "X-CSRFToken": csrfToken ?? ""
            },
            

            credentials: "include",
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        // Get the file blob from the response
        const blob = await response.blob();

        // Create a link to download the file
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;

        // Optionally, get the filename from the response headers
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'output.docx';
        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const filenameMatch = contentDisposition.match(/filename="(.+)"/);
            if (filenameMatch && filenameMatch.length === 2) {
                filename = filenameMatch[1];
            }
        }

        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

    } catch (error) {
        console.error('Error exporting DOCX:', error);
    }
}

export default exportDocxTemplated;
import { SummarizationTypes } from "./summarizationTypes.enum";

type DescriptionsMap = {
  [key in SummarizationTypes]: string;
};

const descriptions: DescriptionsMap = {
  [SummarizationTypes.OneToOne]:
    "This option creates a separate document for each file you upload, ensuring a precise one-to-one match between your input files and the output documents. To use this option, prepare the files you wish to process, ensuring that each file contains the content you wish to convert individually. You can also upload additional files such as Excel spreadsheets, reports or references that will be used as sources for the documents. Upload all these files using the upload interface. Once uploaded, the system will process each file independently, creating a separate document for each input file. This option is ideal for users who need separate, stand-alone documents for each input, such as different reports, articles or papers that should not be merged. For example, if you have several project reports or articles that you need to convert into separate, individual documents, this option will meet your needs by keeping the content of each file separate from the others, while incorporating relevant information from the additional sources. ",
  [SummarizationTypes.MultipleToOne]:
    "This scenario processes all uploaded files and process their content into a single, comprehensive document, providing a consolidated view of all your inputs. To use this option, collect all the files you want to process into a single document and upload these files using the upload interface. The system will analyze and process the content from all uploaded files into one cohesive document. This option is ideal for users who need a unified document that aggregates information from multiple sources, such as comprehensive reports, combined meeting notes, or research papers. For instance, if you have several meeting notes or research papers that you want to compile into a single, cohesive document, this option will merge all the inputs to create one comprehensive output. ",
};

export default descriptions;

import { DraftingTypes } from "./draftingTypes.enum";

type DescriptionsMap = {
  [key in DraftingTypes]: { title: string; info: string }[];
};

const descriptions: DescriptionsMap = {
  [DraftingTypes.OneToOne]: [
    {
      title: "What is one-to-one drafting?",
      info: "This feature creates a separate document for each file you upload, ensuring a one-to-one match between your input and output.",
    },
    {
      title: "How to use?",
      info: "To use the tool upload and select the files you want to process, then choose a template for the system to use when drafting the final documents. Each file will be processed independently, with relevant information extracted and incorporated into the selected template, producing one output document for each input file. For example, if you submit 30 files and a template, you’ll receive 30 unique documents tailored to the content of each file. You can also provide a custom prompt with specific drafting instructions, such as “Insert an executive summary at the beginning of the email” or “Add a legal disclaimer at the end”. Additionally, you can choose the output language or select two languages if you need bilingual documents.",
    },
    {
      title: "When to use?",
      info: "This option is ideal for creating separate, stand-alone documents like requests, emails, meeting notes, engagement letters, proposals, etc. ",
    },
  ],
  [DraftingTypes.MultipleToOne]: [
    {
      title: "What is multiple-to-one drafting?",
      info: "This feature processes all selected files and consolidates their content into a single, comprehensive document by selecting the five most relevant ones based on their content.",
    },
    {
      title: "How to use?",
      info: "To use the tools upload and select the files you want to process, then choose a template for the system to use when drafting the final document. The system will analyze the content, identify the top five most relevant files, and use them to draft the selected template. For example, if you upload 30 files, the system will select the five most relevant ones and use them for drafting the final document. You can also provide a custom prompt with specific drafting instructions, such as “Generate and insert a payment schedule after the financial terms” or “Add a legal disclaimer at the end.” Additionally, you can choose the output language or select two languages if you need bilingual documents.",
    },
    {
      title: "When to use?",
      info: "This option is ideal for users who need a single document that combines information from multiple sources, such as consolidated reports, contracts, annual reviews, etc. ",
    },
  ],
  [DraftingTypes.TemplateBased]: [
    {
      title: "What is template based drafting?",
      info: "This feature allows you to generate drafted documents based on a pre-selected template, custom prompt, and selected language, ensuring the content aligns with your specific instructions.",
    },
    {
      title: "How to use?",
      info: "To use it, select a template that fits the type of document you want to create, then provide a custom prompt to guide the content generation according to your specific needs.",
    },
    {
      title: "When to use?",
      info: "This tool is ideal for users who require standardized documents, such as resumes, proposals, or formal letters.",
    },
  ],
  [DraftingTypes.PromptBased]: [
    {
      title: "What is prompt based drafting?",
      info: "This feature tailors the generated documents according to a custom prompt, tone, style, and language specified by you, allowing for highly personalized outputs.",
    },
    {
      title: "How to use?",
      info: "To use it, provide a detailed custom prompt outlining the specific content you want the document to reflect. Then, select the tone, style, and language from the available drop-down lists. The system will draft the document based entirely on your input, without requiring any uploaded files.",
    },
    {
      title: "When to use?",
      info: "This tool is ideal for users who need shorter documents, up to 10 pages, with a unique voice or style, such as emails, LinkedIn posts, or any document requiring a distinct touch.",
    },
  ],
};

export default descriptions;

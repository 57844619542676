import React, { useContext, useState } from "react";

import { Button as ButtonAntd, Form, Select, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { MdOutlineDataObject, MdTranslate } from "react-icons/md";
import MainContext from "../../../../contexts/MainContext";
import ToolsContext from "../../../../contexts/ToolsContext";
import languagesOptions from "../../../../helpers/languagesOptions";
import filterOption from "../../../../helpers/selectFilterOption";
import ConfirmToolModal from "../ConfirmToolModal/ConfirmToolModal";
import stylesToolBase from "../ToolBase/ToolBase.module.css";
import TransitionToolWrapper from "../TransitionToolWrapper/TransitionToolWrapper";
import { ToolTypes } from "../../../../enums/toolTypes.enum";
import getCookie from "../../../../helpers/getCookie";
import getFileExtension from "../../../../helpers/getFileExtension";
import * as ToolsService from "../../services/tools.service";
import { useToast } from "../../../../contexts/ToastContext";
import truncateFilesArrayPipe from "../../../../helpers/truncateFilesArrayPipe";

import styles from "./TranslationTool.module.css";

interface TranslationForm {
  language: string;
}

const TranslationTool: React.FC = () => {
  const [formData, setFormData] = useState<TranslationForm>({
    language: "English",
  });

  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const mainContext = useContext(MainContext);
  const toolsContext = useContext(ToolsContext);

  const { showSuccessToast, toastResponse } = useToast();

  const csrfToken = getCookie("csrftoken") ?? "";

  const forbiddenExtensions = ["xlsx", "xls", "csv"];

  const isAllowedFileExtension = (fileName: string): boolean => {
    return !forbiddenExtensions.includes(getFileExtension(fileName) ?? "");
  };

  return (
    <>
      <TransitionToolWrapper>
        <div className={stylesToolBase.tool}>
          <div className={stylesToolBase.tool_header}>
            <div className={stylesToolBase.tool_title}>
              <div className={stylesToolBase.ribbon}>
                <MdTranslate />
              </div>
              <div className={stylesToolBase.tool_title_text}>Translation</div>
            </div>
          </div>
          <div className={stylesToolBase.tool_content}>
            <div className={styles.tool_notice}>
              <p className={styles.notice_header}>
                <InfoCircleOutlined size={20} /> Notice
              </p>
              <p className={styles.notice_content}>
                Unprocessed files:{" "}
                <span className={styles.file_extensions}>XLSX, XLS, CSV</span>
              </p>
            </div>

            <Form className={styles.form} layout="vertical">
              <Form.Item
                required
                className={styles.language_input}
                label="Language of the output"
              >
                <Select
                  showSearch
                  filterOption={filterOption}
                  placeholder="Select the language of the output"
                  onChange={(value: string, option: any) =>
                    setFormData({
                      ...formData,
                      language: option.label,
                    })
                  }
                  value={formData.language}
                  options={languagesOptions}
                />
              </Form.Item>
              <Tooltip
                placement="bottom"
                title={
                  toolsContext?.selectedFiles.filter((file) =>
                    isAllowedFileExtension(file.name)
                  ).length === 0 || !formData.language
                    ? "Please select acceptable files you want to work with and desired language for translation."
                    : ""
                }
              >
                <ButtonAntd
                  className={styles.translation_button}
                  icon={<MdTranslate className={styles.button_icon} />}
                  disabled={
                    toolsContext?.selectedFiles.filter((file) =>
                      isAllowedFileExtension(file.name)
                    ).length === 0 || !formData.language
                  }
                  onClick={() => setShowSubmitModal(true)}
                  loading={showSubmitModal}
                  type="primary"
                  size="middle"
                >
                  Start Translation
                </ButtonAntd>
              </Tooltip>
            </Form>
          </div>
        </div>
      </TransitionToolWrapper>

      <ConfirmToolModal
        isOpen={showSubmitModal}
        title="Document Translation"
        subTitle="Proceed by translating following files:"
        icon={<MdOutlineDataObject className={styles.modal_icon} />}
        leftPart={{
          header: "Files",
          body: toolsContext?.selectedFiles
            .filter((file) => isAllowedFileExtension(file.name))
            .map((file) => file.name)!,
        }}
        rightPart={{
          header: "Language",
          body: [formData.language],
        }}
        onClose={() => setShowSubmitModal(false)}
        action={async () => {
          toolsContext?.setSelectedTools((prev) =>
            prev.filter((tool) => tool !== ToolTypes.Translator)
          );

          const responseData = await ToolsService.translateText(
            csrfToken,
            toolsContext?.selectedFiles
              .filter((file) => isAllowedFileExtension(file.name))
              .map((file) => file.id)!,
            formData.language
          );

          toastResponse<{ file_name: string }[]>(responseData).then(
            (result) => {
              showSuccessToast(
                "Success",
                <p>
                  Translation of{" "}
                  <span className={styles.bold_italic_text}>
                    {truncateFilesArrayPipe(
                      result.map(({ file_name }) => file_name)
                    )}{" "}
                  </span>
                  was completed successfully!
                </p>
              );
            }
          );

          await mainContext?.fetchNotifications();
        }}
      />
    </>
  );
};

export default TranslationTool;

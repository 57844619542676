function truncateFilesArrayPipe(fileNamesArray: string[] = []): string {
  if (fileNamesArray.length > 5) {
    const resultView = fileNamesArray.slice(0, 5).join(", ");
    const leftDataLength = fileNamesArray.length - 5;
    return `${resultView} and ${leftDataLength} more ${
      leftDataLength === 1 ? "file" : "files"
    }`;
  } else {
    return fileNamesArray.join(", ");
  }
}

export default truncateFilesArrayPipe;

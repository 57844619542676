import { ExtractionTypes } from "../enums/extractionTypes.enum";

type DescriptionsMap = {
  [key in ExtractionTypes]: string;
};

const descriptions: DescriptionsMap = {
  [ExtractionTypes.OneToOne]:
    "Generates a distinct document for each uploaded file, treating them independently. Returns one result for each individual file.",
  [ExtractionTypes.MultipleToOne]:
    "Combines all uploaded files into a single document for unified processing. Returns a single result.",
};

export default descriptions;

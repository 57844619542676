import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Checkbox, Button, Input, Tooltip, List, Spin } from "antd";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";
import { MdDeleteOutline, MdOutlineClear } from "react-icons/md";
import { IoIosArrowBack } from "react-icons/io";
import { BiSortAZ, BiSortAlt2, BiSortZA } from "react-icons/bi";
import { UploadedFile } from "../../interfaces/uploadedFile.interface";
import FilesUploader from "./components/FilesUploader/FilesUploader";
import DeleteFilesModal from "./components/DeleteFilesModal/DeleteFilesModal";
import MainContext from "../../../../contexts/MainContext";
import ToolsContext from "../../../../contexts/ToolsContext";
import useEffectWithIntervalAndCondition from "./helpers/useEffectWithIntervalAndCondition";
import getCookie from "../../../../helpers/getCookie";
import * as ToolsService from "../../../../pages/ToolsPage/services/tools.service";
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import styles from "./FilesBar.module.css";

interface FilesBarProps {
  isPageLoading: boolean;
  setIsPageLoading: (state: boolean) => void;
}

const sortingIcons = {
  default: <BiSortAlt2 className={styles.sort_icon} />,
  asc: <BiSortAZ className={styles.sort_icon} />,
  desc: <BiSortZA className={styles.sort_icon} />,
};

const sortingFunctions = {
  default: undefined,
  asc: (a: UploadedFile, b: UploadedFile) => a.name.localeCompare(b.name),
  desc: (a: UploadedFile, b: UploadedFile) => b.name.localeCompare(a.name),
};

const FilesBar: React.FC<FilesBarProps> = ({
  isPageLoading,
  setIsPageLoading,
}) => {
  const navigate = useNavigate(); // Initialize the navigate function

  const { teamId, projectId } = useParams<{
    teamId: string;
    projectId: string;
  }>(); // Access params from the URL
  const mainContext = useContext(MainContext);
  const toolsContext = useContext(ToolsContext);

  const [isBarExpanded, setIsBarExpanded] = useState(true);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | "default">(
    "default"
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [filesToDelete, setFilesToDelete] = useState<UploadedFile[]>([]);
  const [searchText, setSearchText] = useState("");
  const [lastSelectedIndex, setLastSelectedIndex] = useState<number | null>(null);
  const updateUrlParams = (newTeamId: string, newProjectId: string) => {
    // navigate(`/${newTeamId}/${newProjectId}/tools`);
  };

  useEffect(() => {
    if (projectId) {
      const selectedProject = mainContext?.projects.find(
        (project) => project.id === parseInt(projectId as string)
      );
      if (selectedProject) {
        console.log("FilesBar");
        mainContext?.setProjectSelected(selectedProject);
        updateUrlParams(
          (mainContext?.teamSelected?.id as number).toString(),
          selectedProject.id.toString()
        );
      }
    }
  }, [projectId, mainContext?.projects, mainContext?.setProjectSelected]);
  useEffectWithIntervalAndCondition({
    logic: () => {
      if (projectId && mainContext?.teams.length !== 0) {
        // Fetch project files based on the current project ID from the URL
        toolsContext?.getProjectFiles(projectId);
      } else {
        toolsContext?.setProjectFiles([]);
      }
    },
    dependencies: [projectId, toolsContext?.isAnyFileProcessing],
    interval: 10000,
    condition: Boolean(toolsContext?.isAnyFileProcessing),
    additionalUnmountLogic: () => {
      toolsContext?.setSelectedFiles([]);
      toolsContext?.setSelectedTools([]);
    },
  });

  const toggleFilesBar = () => {
    setIsBarExpanded((prevState) => !prevState);
  };

  const isIndeterminate =
    toolsContext?.selectedFiles.length! > 0 &&
    toolsContext?.selectedFiles.length! < toolsContext?.projectFiles.length!;

  const handleShiftClick = (file: UploadedFile, index: number, isChecked: boolean) => {
    if (lastSelectedIndex === null) {
      onSelect(file, isChecked);
      setLastSelectedIndex(index);
      return;
    }

    const start = Math.min(lastSelectedIndex, index);
    const end = Math.max(lastSelectedIndex, index);
    
    // Get the filtered list of files
    const filteredFiles = toolsContext?.projectFiles
      .filter((file) => !file.is_processing)
      .filter((file) => 
        (file.folder_path ? `${file.folder_path}/${file.name}` : file.name)
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
      .sort(sortingFunctions[sortOrder]) || [];

    // Select files from the filtered list
    const filesToSelect = filteredFiles.slice(start, end + 1);

    if (isChecked) {
      toolsContext?.setSelectedFiles((prev) => {
        const newSelection = [...prev];
        filesToSelect.forEach((file) => {
          if (!newSelection.find((f) => f.id === file.id)) {
            newSelection.push(file);
          }
        });
        return newSelection;
      });
    } else {
      toolsContext?.setSelectedFiles((prev) =>
        prev.filter((file) => !filesToSelect.find((f) => f.id === file.id))
      );
    }
  };

  const onSelect = (
    file: UploadedFile, 
    isChecked: boolean, 
    index?: number, 
    event?: MouseEvent
  ) => {
    if (event?.shiftKey && index !== undefined) {
      handleShiftClick(file, index, isChecked);
    } else {
      toolsContext?.setSelectedFiles((prev) =>
        isChecked ? [...prev, file] : prev.filter(({ id }) => id !== file.id)
      );
      setLastSelectedIndex(index !== undefined ? index : null);
    }
  };

  const onSelectAll = () => {
    const filesWithoutProcessing = toolsContext?.projectFiles.filter(
      (file) => !file.is_processing
    )!;
    if (toolsContext?.selectedFiles.length === filesWithoutProcessing.length) {
      toolsContext.setSelectedFiles([]);
    } else {
      toolsContext?.setSelectedFiles(filesWithoutProcessing);
    }
  };

  const toggleSortOrder = () => {
    switch (sortOrder) {
      case "asc":
        setSortOrder("desc");
        break;

      case "desc":
        setSortOrder("default");
        break;

      default:
        setSortOrder("asc");
        break;
    }
  };

  const renderFileName = (fileName: string) => {
    const lastDotIndex = fileName.lastIndexOf('.');
    if (lastDotIndex === -1) return fileName;
    
    return (
      <>
        <span>{fileName.substring(0, lastDotIndex)}</span>
        <span className={styles.file_extension}>{fileName.substring(lastDotIndex)}</span>
      </>
    );
  };

  return (
    <>
      <div className={`${styles.files_bar} ${!isBarExpanded && styles.closed}`}>
        <div className={styles.files_bar_header}>
          {isBarExpanded && <FilesUploader />}
          <div
            onClick={toggleFilesBar}
            className={`${styles.toggle_button} ${
              !isBarExpanded && styles.closed
            }`}>
            <IoIosArrowBack />
          </div>
        </div>

        {isBarExpanded && (
          <>
            {toolsContext?.projectFiles.length !== 0 ? (
              <>
                <Input
                  className={styles.search_input}
                  value={searchText}
                  placeholder="Search files"
                  prefix={
                    <SearchOutlined
                      style={{
                        paddingRight: "5px",
                        color: "rgba(255,255,255,.25)",
                      }}
                    />
                  }
                  allowClear
                  size="middle"
                  onChange={(e) => setSearchText(e.target.value)}
                />

                <div className={styles.files_list_title}>
                  <Checkbox
                    indeterminate={isIndeterminate}
                    checked={
                      toolsContext?.selectedFiles.length ===
                      toolsContext?.projectFiles.length
                    }
                    onChange={onSelectAll}
                  />

                  <div className={styles.text_n_sort}>
                    <span className={styles.files_list_title_text}>Files</span>
                    <Button
                      type="text"
                      icon={sortingIcons[sortOrder]}
                      className={styles.files_sort_button}
                      onClick={() => {
                        toggleSortOrder();
                      }}
                    />
                  </div>

                  <Tooltip title="Delete selected files" mouseEnterDelay={1.3}>
                    <Button
                      type="text"
                      icon={
                        <MdDeleteOutline className={styles.files_delete_icon} />
                      }
                      className={styles.files_delete_button}
                      onClick={(e) => {
                        e.stopPropagation();
                        setFilesToDelete(toolsContext?.selectedFiles!);
                        setIsDeleting(true);
                      }}
                      disabled={toolsContext?.selectedFiles.length === 0}
                    />
                  </Tooltip>
                </div>
                <List
                  className={styles.items_container}
                  dataSource={toolsContext?.projectFiles
                    .filter((file) =>
                    (file.folder_path ? `${file.folder_path}/${file.name}` : file.name).toLowerCase().includes(searchText.toLowerCase())
                    )
                    .sort(sortingFunctions[sortOrder])}
                  renderItem={(file, index) => (
                    <List.Item
                      className={`${styles.files_list_item} ${
                        toolsContext?.selectedFiles
                          .map(({ id }) => id)
                          .includes(file.id) && styles.files_list_item_selected
                      } ${file.is_processing && styles.processing_file}`}>
                      {file.is_processing ? (
                        <div className={styles.file_process}>
                          <div className={styles.file_process_spinner}>
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{ fontSize: 14 }}
                                  spin
                                />
                              }
                            />
                          </div>
                          <div className={styles.clear_file_process}>
                            <span
                              className={styles.file_delete_wrapper}
                              onClick={async (e) => {
                                e.stopPropagation();
                                const csrfToken = getCookie("csrftoken") ?? "";
                                await ToolsService.deleteFiles(csrfToken, [
                                  file.id,
                                ]);
                                toolsContext?.getProjectFiles(
                                  projectId as string | number
                                ); // Refresh files for the current project
                              }}>
                              <MdOutlineClear
                                style={{ width: 14, height: 14 }}
                                className={styles.file_delete_icon}
                              />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <Checkbox
                          className={`${styles.file_checkbox} ${
                            toolsContext?.selectedFiles
                              .map(({ id }) => id)
                              .includes(file.id) && styles.file_checkbox_checked
                          }`}
                          checked={toolsContext?.selectedFiles
                            .map(({ id }) => id)
                            .includes(file.id)}
                          onChange={(e: CheckboxChangeEvent) => onSelect(
                            file, 
                            e.target.checked, 
                            index,
                            e.nativeEvent
                          )}
                        />
                      )}
                      {file.is_processing ? (
                        // <Tooltip title={file.name}>
                          <span className={styles.files_list_item_name}>
                            {file.name}
                          </span>
                        // </Tooltip>
                      ) : (
                        <>
                          {/* <Tooltip title={file.name}> */}
                            <span
                              className={`${styles.files_list_item_name} ${styles.preview_button}`}
                              onClick={async (e) => {
                                e.stopPropagation();
                                mainContext?.setTextViewContent({
                                  text: undefined,
                                });
                                mainContext?.setIsTextViewVisible(true);

                                let textResponse =
                                  await mainContext?.get_doc_text(
                                    file.name,
                                    ""
                                  );
                                mainContext?.setTextViewContent({
                                  text: textResponse["text"],
                                });
                              }}>
                              <span className={styles.files_list_item_name}>
                                {file.folder_path ? (
                                  <div className={styles.file_path_structure}>
                                    <div className={styles.folder_path}>{file.folder_path}</div>
                                    <div className={styles.file_name}>{renderFileName(file.name)}</div>
                                  </div>
                                ) : (
                                  <div className={styles.file_name}>{renderFileName(file.name)}</div>
                                )}
                              </span>
                            </span>
                          {/* </Tooltip> */}

                          <span
                            className={styles.file_delete_wrapper}
                            onClick={(e) => {
                              e.stopPropagation();
                              setFilesToDelete([file]);
                              setIsDeleting(true);
                            }}>
                            <MdDeleteOutline
                              className={styles.file_delete_icon}
                            />
                          </span>
                        </>
                      )}
                    </List.Item>
                  )}
                  split={false}
                />
              </>
            ) : (
              <div className={styles.empty_files_state}>No files yet</div>
            )}
          </>
        )}
      </div>

      <DeleteFilesModal
        isOpen={isDeleting}
        onClose={() => setIsDeleting(false)}
        files={filesToDelete}
      />
    </>
  );
};

export default FilesBar;

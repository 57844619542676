import { ToolTypes } from "../../../enums/toolTypes.enum";
import { ResultTypes } from "../enums/resultTypes.enum";

type ToolResultTypesMap = {
  [key in ToolTypes]: ResultTypes | undefined;
};

const ToolResultTypes: ToolResultTypesMap = {
  [ToolTypes.MetadataExtractor]: ResultTypes.Metadata,
  [ToolTypes.ObligationsExtractor]: ResultTypes.Obligations,
  [ToolTypes.RisksIdentifier]: ResultTypes.Risks,
  [ToolTypes.DocumentComparison]: ResultTypes.Comparison,
  [ToolTypes.DocumentDrafting]: ResultTypes.Drafting,
  [ToolTypes.Translator]: ResultTypes.Translation,
  [ToolTypes.DocumentSummarization]: undefined,
  [ToolTypes.DocumentStandardization]: undefined,
  [ToolTypes.TimelineAnalysis]: ResultTypes.Timeline,
  [ToolTypes.CustomTool]: undefined,
  [ToolTypes.TransactionReview]: ResultTypes.Transaction,
};

export default ToolResultTypes;

import { ToolTypes } from "../enums/toolTypes.enum";
import { ResultTypes } from "../../../pages/ResultsPage/enums/resultTypes.enum";

type ToolPathResultTypesMap = {
  [key in ToolTypes]: ResultTypes | undefined;
};

const ToolPathResultTypes: ToolPathResultTypesMap = {
  [ToolTypes.MetadataExtractor]: ResultTypes.Metadata,
  [ToolTypes.ObligationsExtractor]: ResultTypes.Obligations,
  [ToolTypes.RisksIdentifier]: ResultTypes.Risks,
  [ToolTypes.DocumentComparison]: ResultTypes.Comparison,
  [ToolTypes.DocumentDrafting]: ResultTypes.Drafting,
  [ToolTypes.Translator]: ResultTypes.Translation,
  [ToolTypes.DocumentSummarization]: ResultTypes.Summarization,
  [ToolTypes.TimelineAnalysis]: ResultTypes.Timeline,
  [ToolTypes.TransactionReview]: ResultTypes.Transaction,
};

export default ToolPathResultTypes;

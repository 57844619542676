const sizeOptions = [
  {
    label: "Short",
    value: "short",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "Long",
    value: "long",
  },
];

export default sizeOptions;

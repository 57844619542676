export enum ToolTypes {
  MetadataExtractor = "metadata",
  ObligationsExtractor = "obligation",
  RisksIdentifier = "risk",
  DocumentComparison = "comparison",
  DocumentDrafting = "drafting",
  DocumentSummarization = "summarization",
  TimelineAnalysis = "timeline_analysis",
  Translator = "translation",
  TransactionReview = "transaction",
}

import React, { useContext, useState } from "react";
import { ToolTypes } from "../../enums/toolTypes.enum";
import EmptySpot from "./components/EmptySpot/EmptySpot";
import FilesBar from "./components/FilesBar/FilesBar";
import ToolsBar from "./components/ToolsBar/ToolsBar";
import ToolsContext from "../../contexts/ToolsContext";
import ToolsComponents from "./helpers/toolsComponentsMap";
import styles from "./ToolsPage.module.css";
import MainContext from "../../contexts/MainContext";

const ToolsPage: React.FC = () => {
  const mainContext = useContext(MainContext);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const toolsContext = useContext(ToolsContext);

  const renderToolComponent = (tool: ToolTypes) => {
    const ToolComponent = ToolsComponents[tool];
    return <ToolComponent key={tool} />;
  };

  return (
    <div className={styles.tools_page}>
      {mainContext?.projectSelected && <FilesBar
        isPageLoading={isPageLoading}
        setIsPageLoading={setIsPageLoading}
      />}
      <div className={styles.tools_spot}>
        <div className={styles.selected_tools}>
          {toolsContext?.selectedTools.length === 0 ? (
            <EmptySpot />
          ) : (
            toolsContext?.selectedTools.map((tool) => renderToolComponent(tool))
          )}
        </div>

        <ToolsBar />
      </div>
    </div>
  );
};

export default ToolsPage;

import { LoadingOutlined } from "@ant-design/icons";
import React, { useContext, useEffect, useState } from "react";
import MainContext from "../../contexts/MainContext";
import Topbar from "./components/Topbar/Topbar";
import SideMenu from "./components/SideMenu/SideMenu";
import { TextView } from "../../components/TextView";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import App from "../../App";
import styles from "./MainPage.module.css";
import { useLocation } from "react-router-dom";
import usePoliteNavigate from "../../helpers/politeNavigate";

const MainPage: React.FC<{}> = (_) => {
  const mainContext = useContext(MainContext);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [mainLayoutIsLoading, setMainLayoutIsLoading] = useState(false);
  const navigate = useNavigate();
  // const politeNavigate = usePoliteNavigate(navigate, MainContext);
  const { tool } = useParams<{ tool: string }>();

  useEffect(() => {
    const fetchTeamsAndNotifications = async () => {
      await mainContext?.fetchNotifications();
      await mainContext?.fetchTeams();
      setPageIsLoading(false);
    };
    fetchTeamsAndNotifications();
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      if (mainContext?.teamSelected && mainContext?.teams.length !== 0) {
        setMainLayoutIsLoading(true);
        await mainContext?.fetchProjects();
        await mainContext?.fetchMetadataContractTypes();
        await mainContext?.fetchObligationContractTypes();
        await mainContext?.fetchRiskContractTypes();
        await mainContext?.fetchComparisonRegulations();
        await mainContext?.fetchComparisonChecklists();
        setMainLayoutIsLoading(false);
        setPageIsLoading(false);
      }
    };
    fetchProjects();
  }, [mainContext?.teamSelected]);
  useEffect(() => {
    console.log("projectSelected");
    const queryString = window.location.search;

    console.log(mainContext?.menuItemSelected.toLowerCase());
    if (mainContext?.projectSelected) {
      navigate(
        `/${mainContext?.teamSelected?.id}/${
          mainContext?.projectSelected?.id
        }/${mainContext?.menuItemSelected.toLowerCase()}` + queryString
      );
    }
  }, [mainContext?.projectSelected]);

  const [sideMenuIsOpen, setSideMenuIsOpen] = useState(true);

  const location = useLocation();

  return (
    <div className={styles.page}>
      <SideMenu
        sideMenuIsOpen={sideMenuIsOpen}
        onSideMenuToogle={() => {
          setSideMenuIsOpen(!sideMenuIsOpen);
        }}
        pageIsLoading={pageIsLoading}
      />
      <div
        className={`${styles.main_part} ${
          sideMenuIsOpen
            ? styles.main_part_side_menu_opened
            : styles.main_part_side_menu_closed
        }`}>
        <Topbar />

        {mainLayoutIsLoading ? (
          <div className={styles.loading_container}>
            <LoadingOutlined className={styles.loader_icon} />
          </div>
        ) : (
          <Outlet />
        )}

        {mainContext?.isTextViewVisible && (
          <TextView {...mainContext?.textViewContent} />
        )}
      </div>
    </div>
  );
};

export default MainPage;

import { Button, Form, Input, Modal } from "antd";
import React, { useContext, useEffect, useState } from "react";

import styles from "./CreateEditProjectModal.module.css";

import MainContext from "../../../../../../contexts/MainContext";
import Project from "../../../../../../data/Project";
import { useToast } from "../../../../../../contexts/ToastContext";

interface CreateEditProjectModalProps {
  isOpen: boolean;
  closeModal: () => void;
  projectToEdit: Project | undefined;
}

export interface ProjectForm {
  name: string;
  description: string;
  id: number | undefined;
}

const CreateEditProjectModal: React.FC<CreateEditProjectModalProps> = ({
  isOpen,
  closeModal,
  projectToEdit,
}) => {
  const mainContext = useContext(MainContext);
  const { showSuccessToast } = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [projectForm, setProjectForm] = useState<ProjectForm>({
    name: "",
    description: "",
    id: undefined,
  });

  useEffect(() => {
    if (projectToEdit) {
      setProjectForm({
        name: projectToEdit.name,
        description: projectToEdit.description,
        id: projectToEdit.id,
      });
    } else {
      setProjectForm({
        name: "",
        description: "",
        id: undefined,
      });
    }
  }, [isOpen]);

  async function handleCreateProject() {
    const responseData = await mainContext?.createProject(
      projectForm.name,
      projectForm.description
    );

    showSuccessToast(
      "Success",
      <p>
        Project{" "}
        <span className={styles.bold_italic_text}>{responseData?.name}</span>{" "}
        was created successfully!
      </p>
    );
  }

  async function handleEditProject() {
    const responseData = await mainContext?.editProject(projectForm.id!, {
      name: projectForm.name,
      description: projectForm.description,
    });

    showSuccessToast(
      "Success",
      <p>
        Project{" "}
        <span className={styles.bold_italic_text}>{responseData?.name}</span>{" "}
        was edited successfully!
      </p>
    );
  }

  const onSubmit = async () => {
    setIsLoading(true);
    if (projectForm.id) {
      handleEditProject();
    } else {
      handleCreateProject();
    }
    setIsLoading(false);
    closeModal();
  };

  return (
    <Modal
      width="60%"
      centered
      open={isOpen}
      title={[
        <p key="title" className={styles.title}>
          {projectToEdit ? "Edit Project" : "New Project"}
        </p>,
        <p key="subTitle" className={styles.subTitle}>
          {projectToEdit
            ? "Please type new name and description for your project."
            : "Please type a name and a description for your new project."}
        </p>,
      ]}
      onOk={onSubmit}
      onCancel={closeModal}
      footer={[
        <Button key="back" className={styles.close_button} onClick={closeModal}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          className={styles.submit_button}
          disabled={!projectForm.name}
          loading={isLoading}
          onClick={onSubmit}
        >
          Submit
        </Button>,
      ]}
    >
      <Form className={styles.form} layout="vertical">
        <Form.Item required label="Project name">
          <Input
            value={projectForm.name}
            name="name"
            allowClear
            placeholder="Type the project name"
            maxLength={255}
            onChange={(event) =>
              setProjectForm({ ...projectForm, name: event?.target.value })
            }
          />
        </Form.Item>
        <Form.Item label="Project description">
          <Input.TextArea
            value={projectForm.description}
            name="description"
            autoSize={{ minRows: 3, maxRows: 5 }}
            maxLength={200}
            allowClear
            placeholder="Type the project description"
            onChange={(event) =>
              setProjectForm({
                ...projectForm,
                description: event?.target.value,
              })
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateEditProjectModal;

import { ClearOutlined } from "@ant-design/icons";
import {
  Button as ButtonAntd,
  Form,
  Input,
  Radio,
  Select,
  Tooltip,
  UploadFile,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { ImInsertTemplate } from "react-icons/im";
import { TbLayersDifference } from "react-icons/tb";
import MainContext from "../../../../contexts/MainContext";
import TemplatesContext from "../../../../contexts/TemplatesContext";
import ToolsContext from "../../../../contexts/ToolsContext";
import {
  ComparisonChecklistCustomTemplate,
  ComparisonChecklistPredefinedTemplate,
  ComparisonContractTemplate,
  ComparisonPlaybookTemplate,
  ComparisonRegulationCustomTemplate,
  ComparisonRegulationPredefinedTemplate,
} from "../../../../data/Template";
import languagesOptions from "../../../../helpers/languagesOptions";
import filterOption from "../../../../helpers/selectFilterOption";
import ConfirmToolModal from "../ConfirmToolModal/ConfirmToolModal";
import CreateTemplateModal from "../CreateTemplateModal/CreateTemplateModal";
import ToolBase from "../ToolBase/ToolBase";
import TransitionToolWrapper from "../TransitionToolWrapper/TransitionToolWrapper";
import FileUploader from "./components/FileUploader";
import { ToolTypes } from "../../../../enums/toolTypes.enum";
import getCookie from "../../../../helpers/getCookie";
import * as ToolsService from "../../services/tools.service";
// import outputElements from "./helpers/outputElements";
import { useToast } from "../../../../contexts/ToastContext";
import truncateFilesArrayPipe from "../../../../helpers/truncateFilesArrayPipe";

import styles from "./DocumentComparisonTool.module.css";

interface ComparisonForm {
  fileList: UploadFile[];
  comparisonOption: { id: number; name: string } | undefined;
  // outputElements: { label: string; value: number; }[];
  clausesToCheck: string | undefined;
  language: string;
}

interface SubmitRequests {
  [key: string]: {
    tool_action: () => Promise<Response>;
    fetch_template: (
      id: string | number
    ) => Promise<
      | ComparisonPlaybookTemplate
      | ComparisonRegulationCustomTemplate
      | ComparisonRegulationPredefinedTemplate
      | ComparisonContractTemplate
      | ComparisonChecklistCustomTemplate
      | ComparisonChecklistPredefinedTemplate
    >;
    delete_template: (id: string | number) => Promise<void>;
    create_template: (body: {
      name: string;
      file: any;
      specific_clauses_to_check: string;
      language: string;
    }) => Promise<string | number>;
    edit_template: (
      id: string | number,
      body: {
        name: string;
        file: any;
        specific_clauses_to_check: string;
        language: string;
      }
    ) => Promise<void>;
  };
}

const DocumentComparisonTool: React.FC = () => {
  const mainContext = useContext(MainContext);
  const templatesContext = useContext(TemplatesContext);
  const toolsContext = useContext(ToolsContext);

  const { showSuccessToast, toastResponse } = useToast();

  const csrfToken = getCookie("csrftoken") ?? "";

  const [comparisonType, setComparisonType] = useState("playbook");
  const [comparisonStyle, setComparisonStyle] = useState("list");

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showCreateTemplateModal, setShowCreateTemplateModal] = useState(false);

  const [template, setTemplate] = useState<
    | {
        value: number;
        label: string;
        id: number | string;
        comparison_type: string;
      }
    | undefined
  >(undefined);
  const [templates, setTemplates] = useState<
    Array<{
      value: number;
      label: string;
      id: number | string;
      comparison_type: string;
    }>
  >([]);
  const [fetchingTemplates, setFetchingTemplates] = useState(false);

  const [formData, setFormData] = useState<ComparisonForm>({
    fileList: [],
    comparisonOption: undefined,
    // outputElements: [],
    clausesToCheck: undefined,
    language: "English",
  });

  const getTemplate = async (template: {
    value: number;
    label: string;
    id: string | number;
    comparison_type: string;
  }) => {
    setTemplate(template);
    if (!template) {
      setComparisonType("playbook");
      setComparisonStyle("file");
      setFormData({
        fileList: [],
        comparisonOption: undefined,
        clausesToCheck: undefined,
        language: "English",
      });
      return;
    }
    const comparisonTemplate = await submitRequests[
      template.comparison_type
    ].fetch_template(template.id);
    if (!comparisonTemplate) {
      setComparisonType("playbook");
      setComparisonStyle("file");
      setFormData({
        fileList: [],
        comparisonOption: undefined,
        clausesToCheck: undefined,
        language: "English",
      });
    } else {
      setComparisonType(template.comparison_type.split("_")[0]);
      setComparisonStyle(template.comparison_type.split("_")[1] ?? "file");
      setFormData({
        fileList:
          comparisonTemplate.file instanceof File
            ? ([comparisonTemplate.file] as any)
            : [],
        comparisonOption: !(comparisonTemplate.file instanceof File)
          ? comparisonTemplate.file
          : undefined,
        clausesToCheck: comparisonTemplate.specific_clauses_to_check,
        language: comparisonTemplate.language,
      });
    }
  };

  const deleteTemplate = async (data: {
    value: number;
    label: string;
    id: string | number;
    comparison_type: string;
  }) => {
    await submitRequests[data?.comparison_type].delete_template(data.id);
    setTemplates((templates) =>
      templates.filter((template) => template.value !== data.value)
    );
    setTemplate(undefined);
  };

  const createTemplate = async (templateName: string) => {
    let typeOfComparison = comparisonType;
    if (comparisonType !== "playbook" && comparisonType !== "contract") {
      typeOfComparison = `${comparisonType}_${comparisonStyle}`;
    }
    const newTemplateId = await submitRequests[
      typeOfComparison
    ].create_template({
      name: templateName,
      file:
        typeOfComparison === "regulation_list" ||
        typeOfComparison === "checklist_list"
          ? formData.comparisonOption!.id
          : formData.fileList[0],
      specific_clauses_to_check: formData.clausesToCheck ?? "",
      language: formData.language,
    });
    setFetchingTemplates(true);
    const comparisonTemplates = await templatesContext?.fetchAllComparison();
    const parsedTemplates = comparisonTemplates ?? [];
    setTemplate({
      value: 0,
      label: templateName,
      id: newTemplateId,
      comparison_type: comparisonType,
    });
    setTemplates(
      parsedTemplates.map((template) => ({
        value: template.unique_id as number,
        label: template.name,
        id: template.id,
        comparison_type: template.comparison_type,
      }))
    );
    setFetchingTemplates(false);
  };

  const updateTemplate = async (templateName: string) => {
    let typeOfComparison = comparisonType;
    if (comparisonType !== "playbook" && comparisonType !== "contract") {
      typeOfComparison = `${comparisonType}_${comparisonStyle}`;
    }
    await submitRequests[template!.comparison_type].edit_template(
      template!.id,
      {
        name: templateName,
        file:
          template!.comparison_type === "regulation_list" ||
          template!.comparison_type === "checklist_list"
            ? formData.comparisonOption!.id
            : formData.fileList[0],
        specific_clauses_to_check: formData.clausesToCheck ?? "",
        language: formData.language,
      }
    );
    setFetchingTemplates(true);
    const comparisonTemplates = await templatesContext?.fetchAllComparison();
    const parsedTemplates = comparisonTemplates ?? [];
    setTemplate((oldTemplate) => ({
      value: oldTemplate!.value,
      label: templateName,
      id: oldTemplate!.id,
      comparison_type: typeOfComparison,
    }));
    setTemplates(
      parsedTemplates.map((template) => ({
        value: template.unique_id as number,
        label: template.name,
        id: template.id,
        comparison_type: template.comparison_type,
      }))
    );
    setFetchingTemplates(false);
  };

  const submitRequests: SubmitRequests = {
    playbook: {
      tool_action: () =>
        ToolsService.compareDocumentsPlaybook(
          csrfToken,
          toolsContext?.selectedFiles.map((file) => file.id)!,
          formData.clausesToCheck!,
          formData.language,
          formData.fileList[0]
          // formData.outputElements
        ),
      fetch_template: templatesContext?.fetchComparisonPlaybook!,
      delete_template: templatesContext?.deleteComparisonPlaybook!,
      create_template: templatesContext?.createComparisonPlaybook!,
      edit_template: templatesContext?.editComparisonPlaybook!,
    },
    regulation_file: {
      tool_action: () =>
        ToolsService.compareDocumentsCustomRegulation(
          csrfToken,
          toolsContext?.selectedFiles.map((file) => file.id)!,
          formData.clausesToCheck!,
          formData.language,
          formData.fileList[0]
          // formData.outputElements
        ),
      fetch_template: templatesContext?.fetchComparisonRegulationCustom!,
      delete_template: templatesContext?.deleteComparisonRegulationCustom!,
      create_template: templatesContext?.createComparisonRegulationCustom!,
      edit_template: templatesContext?.editComparisonRegulationCustom!,
    },
    regulation_list: {
      tool_action: () =>
        ToolsService.compareDocumentsPredefinedRegulation(
          csrfToken,
          toolsContext?.selectedFiles.map((file) => file.id)!,
          formData.clausesToCheck!,
          formData.language,
          formData.comparisonOption!.name
          // formData.outputElements
        ),
      fetch_template: templatesContext?.fetchComparisonRegulationPredefined!,
      delete_template: templatesContext?.deleteComparisonRegulationPredefined!,
      create_template: templatesContext?.createComparisonRegulationPredefined!,
      edit_template: templatesContext?.editComparisonRegulationPredefined!,
    },
    contract: {
      tool_action: () =>
        ToolsService.compareDocumentsContract(
          csrfToken,
          toolsContext?.selectedFiles.map((file) => file.id)!,
          formData.clausesToCheck!,
          formData.language,
          formData.fileList[0]
          // formData.outputElements
        ),
      fetch_template: templatesContext?.fetchComparisonContract!,
      delete_template: templatesContext?.deleteComparisonContract!,
      create_template: templatesContext?.createComparisonContract!,
      edit_template: templatesContext?.editComparisonContract!,
    },
    checklist_file: {
      tool_action: () =>
        ToolsService.compareDocumentsCustomChecklist(
          csrfToken,
          toolsContext?.selectedFiles.map((file) => file.id)!,
          formData.clausesToCheck!,
          formData.language,
          formData.fileList[0]
          // formData.outputElements
        ),
      fetch_template: templatesContext?.fetchComparisonChecklistCustom!,
      delete_template: templatesContext?.deleteComparisonChecklistCustom!,
      create_template: templatesContext?.createComparisonChecklistCustom!,
      edit_template: templatesContext?.editComparisonChecklistCustom!,
    },
    checklist_list: {
      tool_action: () =>
        ToolsService.compareDocumentsPredefinedChecklist(
          csrfToken,
          toolsContext?.selectedFiles.map((file) => file.id)!,
          formData.clausesToCheck!,
          formData.language,
          formData.comparisonOption!.name
          // formData.outputElements
        ),
      fetch_template: templatesContext?.fetchComparisonChecklistPredefined!,
      delete_template: templatesContext?.deleteComparisonChecklistPredefined!,
      create_template: templatesContext?.createComparisonChecklistPredefined!,
      edit_template: templatesContext?.editComparisonChecklistPredefined!,
    },
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      const comparisonTemplates = await templatesContext?.fetchAllComparison();
      const parsedTemplates = comparisonTemplates ?? [];

      setTemplates(
        parsedTemplates.map((template) => ({
          value: template.unique_id as number,
          label: template.name,
          id: template.id,
          comparison_type: template.comparison_type,
        }))
      );
      setFetchingTemplates(false);
    };

    setFetchingTemplates(true);
    fetchTemplates();
  }, []);

  return (
    <>
      <TransitionToolWrapper>
        <ToolBase
          icon={<TbLayersDifference />}
          title="Document comparison"
          getTemplate={getTemplate}
          template={template}
          templates={templates}
          fetchingTemplates={fetchingTemplates}
          deleteTemplate={deleteTemplate}
        >
          <Form className={styles.form} layout="vertical">
            <Form.Item required label="Comparison type">
              <Radio.Group
                disabled={Boolean(template)}
                buttonStyle="solid"
                value={comparisonType}
                onChange={(event) => {
                  setComparisonType(event.target.value);
                  setFormData({
                    ...formData,
                    fileList: [],
                    comparisonOption: undefined,
                  });
                }}
              >
                <Radio.Button value="playbook">Playbook</Radio.Button>
                <Radio.Button value="regulation">Regulation</Radio.Button>
                <Radio.Button value="contract">Contract</Radio.Button>
                <Radio.Button value="checklist">Checklist</Radio.Button>
              </Radio.Group>
            </Form.Item>

            {["playbook", "contract"].includes(comparisonType) && (
              <Form.Item
                required
                label={`${
                  comparisonType[0].toUpperCase() + comparisonType.slice(1)
                } file`}
              >
                <FileUploader
                  documentType={comparisonType}
                  formData={formData}
                  setFormData={setFormData}
                />
              </Form.Item>
            )}

            {["regulation", "checklist"].includes(comparisonType) && (
              <>
                <Form.Item
                  required
                  label={`${
                    comparisonType[0].toUpperCase() + comparisonType.slice(1)
                  } comparison options`}
                >
                  <Radio.Group
                    disabled={Boolean(template)}
                    buttonStyle="solid"
                    value={comparisonStyle}
                    onChange={(event) => {
                      setComparisonStyle(event.target.value);
                      setFormData({
                        ...formData,
                        fileList: [],
                        comparisonOption: undefined,
                      });
                    }}
                  >
                    <Radio.Button value="list">List</Radio.Button>
                    <Radio.Button value="file">File</Radio.Button>
                  </Radio.Group>
                </Form.Item>

                {comparisonStyle === "list" ? (
                  <Form.Item
                    required
                    label={`${
                      comparisonType[0].toUpperCase() + comparisonType.slice(1)
                    }`}
                  >
                    <Select
                      allowClear
                      showSearch
                      filterOption={filterOption}
                      placeholder="Select regulation"
                      onChange={(value: string, option: any) =>
                        setFormData({
                          ...formData,
                          comparisonOption: option
                            ? { id: option.value, name: option.label }
                            : undefined,
                        })
                      }
                      value={formData.comparisonOption?.name}
                      options={
                        comparisonType === "regulation"
                          ? mainContext?.comparisonRegulationTypes.map(
                              (regulation) => ({
                                value: regulation.id,
                                label: regulation.name,
                              })
                            )
                          : mainContext?.comparisonChecklistTypes.map(
                              (checklist) => ({
                                value: checklist.id,
                                label: checklist.name,
                              })
                            )
                      }
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    required
                    label={`${
                      comparisonType[0].toUpperCase() + comparisonType.slice(1)
                    } file`}
                  >
                    <FileUploader
                      documentType={comparisonType}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  </Form.Item>
                )}
              </>
            )}

            <div className={styles.divider}></div>

            {/* <Form.Item label="Output elements">
              <Select
                mode="multiple"
                size="middle"
                filterOption={filterOption}
                allowClear
                showSearch
                placeholder="Select output elements"
                onChange={
                  (value, option: any) => setFormData(
                    {
                      ...formData,
                      outputElements: option ? option : []
                    }
                  )
                }
                value={formData.outputElements}
                options={
                  ['regulation', 'checklist'].includes(comparisonType)
                    ? outputElements.filter(item => item.name !== 'Redrafts').map(outputElement => ({
                      value: outputElement.id,
                      label: outputElement.name
                    }))
                    : outputElements.map(outputElement => ({
                      value: outputElement.id,
                      label: outputElement.name
                    }))
                }
              />
            </Form.Item> */}

            {comparisonType !== "checklist" && (
              <Form.Item label="Specific clauses to check">
                <Input.TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  maxLength={100}
                  allowClear
                  placeholder="Type some specific clauses to check"
                  value={formData.clausesToCheck}
                  onChange={(event) =>
                    setFormData({
                      ...formData,
                      clausesToCheck: event.target.value,
                    })
                  }
                />
              </Form.Item>
            )}

            <Form.Item
              className={styles.language_input}
              label="Language of the output"
            >
              <Select
                showSearch
                filterOption={filterOption}
                placeholder="Select the language of the output"
                onChange={(value: string, option: any) =>
                  setFormData({
                    ...formData,
                    language: option.label,
                  })
                }
                value={formData.language}
                options={languagesOptions}
              />
            </Form.Item>

            <div className={styles.tool_footer}>
              <ButtonAntd
                type="primary"
                ghost
                size="middle"
                icon={<ClearOutlined className={styles.button_icon} />}
                disabled={
                  (formData.fileList.length === 0 &&
                    !formData.comparisonOption &&
                    // formData.outputElements.length === 0 &&
                    !formData.clausesToCheck) ||
                  !!template
                }
                onClick={() =>
                  setFormData({
                    fileList: [],
                    comparisonOption: undefined,
                    // outputElements: [],
                    clausesToCheck: undefined,
                    language: "English",
                  })
                }
              >
                Clear All Fields
              </ButtonAntd>

              <Tooltip
                placement="bottom"
                title={
                  toolsContext?.selectedFiles.length === 0 ||
                  (formData.fileList.length === 0 &&
                    !formData.comparisonOption) ||
                  !formData.language
                    ? "Please select the files you want to compare and complete all the required fields from the tool."
                    : ""
                }
              >
                <ButtonAntd
                  type="primary"
                  size="middle"
                  icon={<TbLayersDifference className={styles.button_icon} />}
                  disabled={
                    toolsContext?.selectedFiles.length === 0 ||
                    (formData.fileList.length === 0 &&
                      !formData.comparisonOption) ||
                    !formData.language
                  }
                  onClick={() => setShowSubmitModal(true)}
                  loading={showSubmitModal}
                >
                  Compare Documents
                </ButtonAntd>
              </Tooltip>

              <ButtonAntd
                type="primary"
                ghost
                size="middle"
                icon={<ImInsertTemplate className={styles.button_icon} />}
                disabled={
                  (formData.fileList.length === 0 &&
                    !formData.comparisonOption) ||
                  // formData.outputElements.length === 0 ||
                  // !formData.clausesToCheck ||
                  !formData.language
                }
                onClick={() => setShowCreateTemplateModal(true)}
              >
                {template ? "Update Template" : "Create Template"}
              </ButtonAntd>
            </div>
          </Form>
        </ToolBase>
      </TransitionToolWrapper>

      <ConfirmToolModal
        isOpen={showSubmitModal}
        title="Compare Documents"
        subTitle="Proceed by comparing documents:"
        icon={<TbLayersDifference className={styles.modal_icon} />}
        leftPart={{
          header: "Source / Base Files",
          body: toolsContext?.selectedFiles.map((file) => file.name)!,
        }}
        rightPart={{
          header: "Target / Comparison File",
          body: formData.fileList.map((file) => file.name),
        }}
        onClose={() => setShowSubmitModal(false)}
        action={async () => {
          toolsContext?.setSelectedTools((prev) =>
            prev.filter((tool) => tool !== ToolTypes.DocumentComparison)
          );

          const request =
            submitRequests[
              ["regulation", "checklist"].includes(comparisonType)
                ? `${comparisonType}_${comparisonStyle}`
                : comparisonType
            ].tool_action;
          const responseData = await request();

          toastResponse<{ file_name: string }[]>(responseData).then(
            (result) => {
              showSuccessToast(
                "Success",
                <p>
                  Comparing documents from{" "}
                  <span className={styles.bold_italic_text}>
                    {truncateFilesArrayPipe(
                      result.map(({ file_name }) => file_name)
                    )}{" "}
                  </span>
                  was completed successfully!
                </p>
              );
            }
          );

          await mainContext?.fetchNotifications();
        }}
      />

      <CreateTemplateModal
        isOpen={showCreateTemplateModal}
        title={
          template
            ? "Update Document Comparison"
            : "New Document Comparison Template"
        }
        subTitle={
          template
            ? "Type a new name for this template"
            : "Please type a name for new document comparison template."
        }
        onClose={() => setShowCreateTemplateModal(false)}
        action={template ? updateTemplate : createTemplate}
        predefinedValue={template ? template.label : ""}
      />
    </>
  );
};

export default DocumentComparisonTool;

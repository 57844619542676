export enum ToolTypes {
  MetadataExtractor = "MetadataExtractor",
  ObligationsExtractor = "ObligationsExtractor",
  RisksIdentifier = "RisksIdentifier",
  DocumentComparison = "DocumentComparison",
  DocumentDrafting = "DocumentDrafting",
  DocumentSummarization = "DocumentSummarization",
  DocumentStandardization = "DocumentStandardization",
  TimelineAnalysis = "TimelineAnalysis",
  Translator = "Translator",
  TransactionReview = "TransactionReview",
  CustomTool = "CustomTool",
}

import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import type { ProColumns } from "@ant-design/pro-components";
import { Button, Empty, Popconfirm, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MdDeleteOutline, MdEdit } from "react-icons/md";

import TeamsContext from "../../../../../../contexts/TeamsContext";
import TypesContext from "../../../../../../contexts/ToolInputsContext";
import { RiskContractType } from "../../../../../../data/PredefinedToolInputs";
import equalObjects from "../../../../../../helpers/equalObjects";
import generateUUID from "../../../../../../helpers/generateUUID";
import EditableTable from "../EditableTable/EditableTable";
import styles from "./RisksIdentifierInputs.module.css";

import * as TypesService from "../../services/predefinedData.service";
import CreateEditContractTypeModal from "../CreateEditContractTypeModal/CreateEditContractTypeModal";

interface DataSourceType {
  key: React.Key;
  id: React.Key;
  risk: string;
  description: string;
}

const RiskExtractorInputs: React.FC<{}> = (_) => {
  const teamsContext = useContext(TeamsContext);
  const typesContext = useContext(TypesContext);

  const [isLoading, setIsLoading] = useState(true);
  const [isTableLoading, setIsTableLoading] = useState<{
    [key: string]: boolean;
  }>({});
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);

  const [showCreateEditContractTypeModal, setShowCreateEditContractTypeModal] =
    useState(false);
  const [modalContractType, setModalContractType] = useState<
    RiskContractType | undefined
  >(undefined);

  const isAdmin = () => {
    return teamsContext?.teamSelected?.user_role === "Admin";
  };

  const columns: ({
    riskContractTypeId,
  }: {
    riskContractTypeId: React.Key;
  }) => ProColumns<DataSourceType>[] = ({
    riskContractTypeId,
  }: {
    riskContractTypeId: React.Key;
  }) => [
    {
      title: "Risk",
      dataIndex: "risk",
      valueType: "text",
      formItemProps: () => ({
        rules: [
          {
            required: true,
            max: 100,
            message: "Invalid input: between 0 - 100 characters.",
          },
        ],
      }),
      fieldProps: () => ({ placeholder: "Risk name" }),
      width: "20vw",
    },
    {
      title: "Description",
      dataIndex: "description",
      valueType: "text",
      fieldProps: () => ({ placeholder: "Risk description" }),
      width: "40vw",
    },
    {
      title: "Actions",
      valueType: "option",
      width: "10vw",
      render: (text, record, _, action) => [
        <Tooltip key={`${record.id}_edit`} title="Edit" placement="left">
          <Button
            type="link"
            icon={<MdEdit color="var(--deloitte-green)" size="22" />}
            onClick={(e) => {
              e.preventDefault();
              action?.startEditable?.(record.id);
            }}
          />
        </Tooltip>,
        <Tooltip key={`${record.id}_delete`} title="Delete" placement="right">
          <Popconfirm
            title="Are you sure you want to delete this item?"
            okText="Yes"
            cancelText="No"
            onConfirm={async () => {
              handleTableLoading(riskContractTypeId, true);
              await TypesService.deleteRiskType(record.id.toString());
              typesContext?.fetchRiskContractTypes(
                teamsContext?.teamSelected?.id!
              );
              handleTableLoading(riskContractTypeId, false);
            }}>
            <Button
              type="link"
              icon={<MdDeleteOutline color="var(--beautiful-red)" size="22" />}
            />
          </Popconfirm>
        </Tooltip>,
      ],
      hidden: teamsContext?.teamSelected?.user_role !== "Admin",
    },
  ];

  useEffect(() => {
    const fetchToolsInput = async () => {
      await typesContext?.fetchRiskContractTypes(
        teamsContext?.teamSelected?.id!
      );
      setIsLoading(false);
    };
    setIsLoading(true);
    fetchToolsInput();
  }, [teamsContext?.teamSelected]);

  const handleTableLoading = (id: React.Key, loading: boolean) => {
    setIsTableLoading((prevState) => ({
      ...prevState,
      [id.toString()]: loading,
    }));
  };

  return isLoading ? (
    <div
      style={{
        flex: 1,
        display: "flex",
        alignSelf: "center",
        justifyContent: "center",
      }}>
      <LoadingOutlined
        style={{ fontSize: 20, color: "var(--deloitte-green)" }}
      />
    </div>
  ) : (
    <div className={styles.content}>
      {isAdmin() ? (
        <div className={styles.add_contract_type}>
          <Button
            onClick={() => {
              setModalContractType(undefined);
              setShowCreateEditContractTypeModal(true);
            }}
            icon={<PlusOutlined />}
            type="primary">
            Add document type
          </Button>
        </div>
      ) : undefined}
      {typesContext?.riskContractTypes.map((riskContractType) => (
        <div
          key={riskContractType.id}
          className={styles.contract_type_item_content}>
          <div className={styles.contract_type_item_title}>
            <div>{riskContractType.name}</div>
            {isAdmin() ? (
              <Tooltip title="Edit document type name" placement="top">
                <Button
                  type="link"
                  style={{ marginLeft: 16 }}
                  icon={<MdEdit color="var(--deloitte-green)" size="22" />}
                  onClick={() => {
                    setModalContractType(riskContractType);
                    setShowCreateEditContractTypeModal(true);
                  }}
                />
              </Tooltip>
            ) : undefined}
            {isAdmin() ? (
              <Tooltip title="Delete document type" placement="top">
                <Popconfirm
                  title="Are you sure you want to delete this item?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={async () => {
                    await TypesService?.deleteRiskContractType(
                      riskContractType.id
                    );
                    typesContext?.fetchRiskContractTypes(
                      teamsContext?.teamSelected?.id!
                    );
                  }}>
                  <Button
                    type="link"
                    icon={
                      <MdDeleteOutline color="var(--beautiful-red)" size="22" />
                    }
                  />
                </Popconfirm>
              </Tooltip>
            ) : undefined}
          </div>
          <EditableTable<DataSourceType>
            className={styles.contract_type_item}
            rowKey="id"
            showHeader
            tableStyle={{ top: 30 }}
            tableLayout="fixed"
            loading={isTableLoading[riskContractType.id] || false}
            recordCreatorProps={
              isAdmin()
                ? {
                    position: "bottom",
                    record: () => ({
                      id: generateUUID(),
                    }),
                    creatorButtonText: "Add new risk type",
                  }
                : false
            }
            columns={columns({ riskContractTypeId: riskContractType.id })}
            value={riskContractType.risk_types.map((riskType, index) => ({
              key: index,
              id: riskType.id,
              risk: riskType.name,
              description: riskType.description,
            }))}
            editable={{
              type: "multiple",
              editableKeys: editableKeys,
              onSave: async (key, data, row) => {
                const isNew = row.key === undefined;
                if (isNew) {
                  handleTableLoading(riskContractType.id, true);
                  await TypesService?.createRiskType(riskContractType.id, {
                    name: data.risk,
                    description: data.description,
                  });
                  typesContext?.fetchRiskContractTypes(
                    teamsContext?.teamSelected?.id!
                  );
                  handleTableLoading(riskContractType.id, false);
                  return;
                }
                if (equalObjects(data, row)) return;
                handleTableLoading(riskContractType.id, true);
                await TypesService?.editRiskType(data.id.toString(), {
                  name: data.risk,
                  description: data.description,
                });
                typesContext?.fetchRiskContractTypes(
                  teamsContext?.teamSelected?.id!
                );
                handleTableLoading(riskContractType.id, false);
              },
              onChange: setEditableRowKeys,
              onlyAddOneLineAlertMessage:
                "Please add only one item per contract type at a time.",
              cancelText: <div style={{ color: "#c7c7c7" }}>Cancel</div>,
              saveText: (
                <div style={{ color: "var(--deloitte-green)" }}>Save</div>
              ),
              actionRender: (_, __, dom) => [dom.save, dom.cancel],
            }}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No risk in this contract type"
                />
              ),
            }}
          />
        </div>
      ))}
      <CreateEditContractTypeModal
        isOpen={showCreateEditContractTypeModal}
        closeModal={async () => {
          typesContext?.fetchRiskContractTypes(teamsContext?.teamSelected?.id!);
          setShowCreateEditContractTypeModal(false);
        }}
        contractTypeToEdit={modalContractType}
        createContractTypeFn={TypesService.createRiskContractType}
        editContractTypeFn={
          TypesService.editRiskContractType
        }></CreateEditContractTypeModal>
    </div>
  );
};

export default RiskExtractorInputs;

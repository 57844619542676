import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Dropdown, Space, MenuProps, Modal } from "antd";
import { MdOutlineManageAccounts } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { CiLogout } from "react-icons/ci";
import AuthContext from "../../../../../../contexts/AuthContext";
import ToolsContext from "../../../../../../contexts/ToolsContext";

import styles from "./UserAvatar.module.css";

const UserAvatar: React.FC = () => {
  const authContext = useContext(AuthContext);
  const toolsContext = useContext(ToolsContext);
  const navigate = useNavigate();
  const [showConfirmLeavePageModal, setShowConfirmLeavePageModal] =
    useState(false);

  const items: MenuProps["items"] = [
    {
      label: (
        <Space className={styles.user}>
          <Avatar
            src={authContext?.user?.profilePicture}
            icon={authContext?.user?.firstname[0]}
          />
          <div>
            <p>{authContext?.user?.fullname}</p>
            <p>{authContext?.user?.email}</p>
          </div>
        </Space>
      ),
      key: "0",
      disabled: true,
    },
    {
      type: "divider",
    },
    {
      label: (
        <p
          className={styles.list_item}
          onClick={() => {
            if (
              toolsContext?.selectedFiles.length !== 0 ||
              toolsContext.selectedTools.length !== 0
            )
              setShowConfirmLeavePageModal(true);
            else navigate("/teams");
          }}
        >
          <MdOutlineManageAccounts /> Manage teams and tools
        </p>
      ),
      key: "1",
    },
    // {
    //   label: (
    //     <p className={styles.list_item}>
    //       <IoSettingsOutline />
    //       Settings
    //     </p>
    //   ),
    //   key: "2",
    // },
    {
      type: "divider",
    },
    {
      label: (
        <p className={styles.list_item} onClick={authContext?.logout}>
          <CiLogout />
          Log out
        </p>
      ),
      key: "3",
    },
  ];
  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]} placement="bottomRight">
        <Avatar
          src={authContext?.user?.profilePicture}
          icon={authContext?.user?.firstname[0]}
        />
      </Dropdown>

      <Modal
        centered
        title="Are you sure you want to leave the page?"
        open={showConfirmLeavePageModal}
        onOk={() => navigate("/teams")}
        onCancel={() => setShowConfirmLeavePageModal(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>You have unsaved changes.</p>
      </Modal>
    </>
  );
};

export default UserAvatar;

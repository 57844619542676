import React from "react";
import { ToolTypes } from "../../../enums/toolTypes.enum";
import MetadataExtractorTool from "../components/MetadataExtractorTool/MetadataExtractorTool";
import DocumentDraftingTool from "../components/DocumentDraftingTool/DocumentDraftingTool";
import TranslationTool from "../components/TranslationTool/TranslationTool";
import DocumentComparisonTool from "../components/DocumentComparisonTool/DocumentComparisonTool";
import ObligationsExtractorTool from "../components/ObligationsExtractorTool/ObligationsExtractorTool";
import RisksIndentifierTool from "../components/RisksIdentifierTool/RisksIdentifierTool";
import DocumentSummarizationTool from "../components/DocumentSummarization/DocumentSummarizationTool";
import TimelineAnalysisTool from "../components/TimelineAnalysisTool/TimelineAnalysisTool";
import DocumentStandardizationTool from "../components/DocumentStandardizationTool/DocumentStandardizationTool";
import CustomTool from "../components/CustomTool/CustomTool";
import TransactionReviewTool from "../components/TransactionReviewTool/TransactionReviewTool";

type ToolsComponentsMap = {
  [key in ToolTypes]: React.ComponentType;
};

const ToolsComponents: ToolsComponentsMap = {
  [ToolTypes.MetadataExtractor]: MetadataExtractorTool,
  [ToolTypes.DocumentDrafting]: DocumentDraftingTool,
  [ToolTypes.Translator]: TranslationTool,
  [ToolTypes.DocumentComparison]: DocumentComparisonTool,
  [ToolTypes.ObligationsExtractor]: ObligationsExtractorTool,
  [ToolTypes.RisksIdentifier]: RisksIndentifierTool,
  [ToolTypes.DocumentSummarization]: DocumentSummarizationTool,
  [ToolTypes.TimelineAnalysis]: TimelineAnalysisTool,
  [ToolTypes.DocumentStandardization]: DocumentStandardizationTool,
  [ToolTypes.TransactionReview]: TransactionReviewTool,
  [ToolTypes.CustomTool]: CustomTool,
};

export default ToolsComponents;

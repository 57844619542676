import React, { ReactNode } from "react";
import { Spin } from "antd";

const SpinWrapper: React.FC<{ isLoading: boolean; children: ReactNode }> = ({
  isLoading,
  children,
}) => {
  return (
    <Spin spinning={isLoading} size="default">
      {children}
    </Spin>
  );
};

export default SpinWrapper;

import React, { createContext, useContext, ReactNode } from "react";
import { notification } from "antd";

interface ToastContextType {
  showSuccessToast: (message: string, description?: string | ReactNode) => void;
  showErrorToast: (message: string, description?: string | ReactNode) => void;
  showWarningToast: (message: string, description?: string | ReactNode) => void;
  showInfoToast: (message: string, description?: string | ReactNode) => void;
  toastedFetch: (url: string, options: RequestInit) => Promise<Response>;
  toastResponse: <T>(response: Response) => Promise<T>;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [api, contextHolder] = notification.useNotification();

  const showSuccessToast = (
    message: string,
    description?: string | ReactNode
  ) => {
    api.success({
      message,
      description,
    });
  };

  const showErrorToast = (
    message: string,
    description?: string | ReactNode
  ) => {
    api.error({
      message,
      description,
    });
  };

  const showWarningToast = (
    message: string,
    description?: string | ReactNode
  ) => {
    api.warning({
      message,
      description,
    });
  };

  const showInfoToast = (message: string, description?: string | ReactNode) => {
    api.info({
      message,
      description,
    });
  };

  const toastedFetch = async (
    url: string,
    options: RequestInit
  ): Promise<Response> => {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData);
      }
      return response;
    } catch (error) {
      showErrorToast(
        (error instanceof Error && error.name) || "Error",
        (error instanceof Error && error.message) || "Something went wrong."
      );
      throw error;
    }
  };

  async function toastResponse<T>(response: Response): Promise<T> {
    try {
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData));
      }
      const data: T = await response.json();
      return data;
    } catch (error) {
      showErrorToast(
        (error instanceof Error && error.name) || "Error",
        (error instanceof Error && error.message) || "Something went wrong."
      );
      throw error;
    }
  }

  return (
    <ToastContext.Provider
      value={{
        showSuccessToast,
        showErrorToast,
        showWarningToast,
        showInfoToast,
        toastedFetch,
        toastResponse,
      }}
    >
      {contextHolder}
      {children}
    </ToastContext.Provider>
  );
};
